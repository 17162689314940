.tabs-container {
  background-color: transparent;
  width: 100%;
  
  .tabs-body {
   width: 100%;
  }

  .tabs-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;

    .tabs-switch-mode {
      display: flex;
      flex-direction: row;
      background-color: var(--clr-gray300);
      padding: .4rem .5rem .4rem .5rem;
      border-radius: 8px;
      gap: .5rem;
      margin-right: .2rem;

      span {
        padding: .3rem .3rem 0 .3rem;
        svg {
          padding: 0;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--secondary-color);
          border-radius: 4px;
        }
      }

      .tabs-active-mode {
        background-color: var(--secondary-color);
        padding: .3rem .3rem 0 .3rem;
        border-radius: 4px;
      }
    }
  }

  .tabs-header {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    
    .heading {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
      margin-right: 1rem;
    }

    .tabs-item {
      display: flex;
      align-items: center;
      gap: .5rem;
      font-size: 1rem;
      color: var(--clr-gray350);
      padding: .2rem 2rem;

      svg {
        width: 22px;
        height: 22px;
        stroke: var(--clr-gray350);
      }

      &:hover {
        color: var(--clr-gray500);
        background-color: var(--clr-gray200);
        cursor: pointer;
      }
    }

    .active {
      color: var(--color0);
      background-color: rgba(#2463eb, .1);

      &:hover {
        color: var(--color0);
        background-color: rgba(#2463eb, .1);
      }
    }
  }
}