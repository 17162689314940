.sortable {
  cursor: pointer;
  user-select: none;
}

.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content min-content;
  grid-gap: 4px;
  align-items: center;
}

.tableWrapper {
  overflow-x: auto;
  overflow-y: auto;
  width: auto;
  max-height: 60vh;
  border-bottom: 1px solid var(--clr-gray300);
  border-top: 1px solid var(--clr-gray300);
}

.exports {
  background-color: var(--secondary-color);
  .controls {
    display: flex;
    flex-direction: row;
    gap: .5rem;

    :global(.new-button) {
      padding: 7px 14px;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid var(--active);
      }
    }
  }

  .downloadColumn {
    width: 100px;
    height: 60px;
    text-align: center;
    vertical-align: middle;

    svg {
      width: 24px;
    }
  }

  tr, td {
    border: 1px solid var(--clr-gray300);
  }

  td.exportObject {
    display: flex;
    flex-direction: column;
    border: 0;

    & > span  >  a {
      color: var(--main-color);

      &:hover {
        cursor: pointer;
      }
    }
  }

  td  {
    font-size: .9rem;
    vertical-align: top;
    width: fit-content;

    i {
      font-size: 1.2rem;
    }
  }

  td:last-of-type {
    position: sticky;
    right: -1px;
    background-color: var(--secondary-color);
    // border-top: none;
    white-space: wrap;
    min-width: 50px;
    max-width: 150px;

    &::before {
      content: '';
      width: 2px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--clr-gray300);
      box-shadow: 3px 0px 2px -1px rgba(0,0,0,0.18);

    }

  }

}

:global(.link) {
  :global(.new-button) {
    padding: .2rem .5rem;
  }
}

.icon {
  vertical-align: middle;
  opacity: 0;
  transition: opacity 300ms ease;

  &.visible {
    opacity: 1;
  }

  &.reversed {
    transform: rotate(180deg);
  }
}

.searchWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.searchInput {
  width: 100%;
}

@media only screen and (min-width: 2100px) {
  .exports {
    tr, td {
      font-size: 1.2rem;
    }
  }
}

