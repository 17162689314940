$thumb-size: 16px;

@mixin track-styles {
  appearance: none;
  background: transparent;
  border: transparent;
}

@mixin thumb-styles {
  appearance: none;
  pointer-events: all;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: red;

  &:active {
    cursor: grabbing;
  }
}

.rangeSlider {
  

  .inputsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: .5rem;
    margin: 1rem 0 0 0;

    .inputContainer {
      width: 45%;
      position: relative;
    }

    .output {
      width: 100%;
      padding: .5rem 1rem;
      border-radius: 4px;
      outline: none;
      border:  1px solid var(--clr-gray300);
      font-weight: 500;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.02);

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance: textfield;
      }
  
      &#from {
        padding-left: 3.5rem;
      }

      &#to {
        padding-left: 2.3rem;
      }

      &:focus {
        border: 1px solid var(--clr-gray350);
      }

      &:invalid {
        border: 1px solid var(--color2);
      }
    }

    label{
      position: absolute;
      left: .5rem;
      top: .5rem;
      color: var(--clr-gray350);
      font-size: .9rem;
    }
  }

}

.sliderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 .6rem;
  .heading {
    margin: 10px .2rem;
    padding-top: .7rem;
  }
}

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px calc(#{$thumb-size} / 2);
  padding-top: .7rem;
  height: calc(#{$thumb-size} + .7rem);
  flex: 1 1 auto;
}

.inputWrapper {
  width: calc(100% + #{$thumb-size});
  margin: 0 calc(#{$thumb-size} / -2);
  position: absolute;
  height: $thumb-size;
}

.controlWrapper {
  width: 100%;
  position: absolute;
  height: $thumb-size;
}

.input {
  position: absolute;
  width: 100%;
  pointer-events: none;
  appearance: none;
  height: 100%;
  opacity: 0;
  z-index: 3;
  padding: 0;

  &::-ms-track {
    @include track-styles;
  }

  &::-moz-range-track {
    @include track-styles;
  }

  &:focus::-webkit-slider-runnable-track {
    @include track-styles;
  }

  &::-ms-thumb {
    @include thumb-styles;
  }

  &::-moz-range-thumb {
    @include thumb-styles;
  }

  &::-webkit-slider-thumb {
    @include thumb-styles;
  }
};

.rail {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: 2px;
  border-radius: 3px;
  background: lightgrey;
}

.innerRail {
  position: absolute;
  height: 100%;
  background: var(--color0);
  // opacity: 0.5;
}

.control {
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 50%;
  position: absolute;
  background:  var(--color0);
  top: 50%;
  margin-left: calc(#{$thumb-size} / -2);
  transform: translate3d(0, -50%, 0);
  z-index: 2;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 5px 4px -4px rgba(0, 0, 0, 0.09);
}





