.searchWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.searchInput {
  width: 100%;
}

.actorsHeader {
  background-color: var(--clr-gray300);
}