.root {
  .label {
    display: block;
    margin-bottom: 8px;
  }

  .tableHeader {
    font-size: 12px;
    padding: 0;
    margin-bottom: 8px;
  }
}

.root .tableRow {
  padding: 0 16px !important;
  height: 40px;
}

.root thead .tableRow {
  padding: 0.5em 1em !important;
}

.submit {
  display: inline-block;
}

.searchWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.searchInput {
  width: 100%;
}
