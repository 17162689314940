
#simplemde-editor-1-wrapper {
  max-width: 800px;
  min-width: 600px;
}

.editor-toolbar button {
  color: var(--clr-gray600)!important;
}

textarea#simplemde-editor-1 {
  color: var(--clr-gray800);
  font-size: 1.6rem;
  line-height: 1.5;
}

.editor-preview {
  & > ul, ol {
    columns: 1!important;
    -webkit-columns: 1!important;
    -moz-columns: 1!important;
  }

  & > ul li{
    list-style-type: disc!important;
    list-style-position: inside;
    margin-left: 1rem;
  }
  & > ol li{
    list-style-type: decimal!important;
    list-style-position: inside;
  }
}