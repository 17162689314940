.root {
    display: flex;
    flex-direction: column;
}

.modalError {
  color: var(--error-primary);
  font-size: .9rem;
}

.queryInput {
  flex: 100% 0 0;
  background-color: var(--secondary-color);
  border-radius: .5rem;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.02);
  z-index: 30;
}

.searchWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.metricsWrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.searchCheckboxesWrapper {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.multilingualSearchCheckboxWrapper, .aipoweredSearchCheckboxWrapper {
  margin-bottom: 20px;
  align-self: start;
  color: #2C2E30;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;

  svg {
    width: 20px;
    height: 20px;
    color: #D9DBDD;
  }
}

.save {
  // background-color: #2563eb;
}

.date {
    background-color: var(--secondary-color);
    color: inherit;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.02);
}

.filters {
    background-color: var(--clr-gray300);
    color: inherit;
    border: none;
}


.sortable {
  cursor: pointer;
  user-select: none;
}

.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content min-content;
  grid-gap: 4px;
  align-items: center;
}

.icon {
  vertical-align: middle;
  opacity: 0;
  transition: opacity 300ms ease;

  &.visible {
    opacity: 1;
  }

  &.reversed {
    transform: rotate(180deg);
  }
}

// td:first-child {
//     width: auto !important;
// }

.noResults {
    margin: 4rem auto;
    font-weight: 500;
    text-align: center;

    a {
      text-decoration: underline;
      color: var(--clr-gray400);

      &:hover {
        color: var(--color0);
      }
    }
}


.calendarWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
}

.searchInput {
  box-shadow: none;
  border: 1px solid var(--clr-gray200);
}

.searchIconWrapper {
  margin-right: 3px;

  svg {
    width: 24px;
    height: 24px;
    color: var(--clr-gray350);
  }
}

.similarMessagesSearchWrapper {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--clr-gray150);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--clr-gray300);
  padding: 1rem;
  position: relative;

  .closeIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  .infoIcon {
    color: var(--clr-gray400);
    display: flex;
    align-items: center;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .messageText {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    color: var(--clr-gray400);

    .title {
      text-transform: uppercase;
      font-size: .9rem;
      font-weight: 500;
      color: var(--clr-gray500);
      margin-top: .5rem;

      .messageActorName {
        color: var(--main-color);
        text-transform: none;
      }
    }

    .text {
      border-left-style: solid;
      border-left-width: 6px;
      border-left-color: var(--clr-gray300);
      padding-left: .5rem;
    }
  }

}

.modalList {
  height: 20rem;

  .modalListItem {
    display: flex;
    gap: 1.2rem;
    border-bottom: 2px solid var(--clr-gray200);
    padding: 1rem;
    font-size: .9rem;
  
    &:last-child {
      border-bottom: none;
    }
  
    &:hover {
      cursor: pointer;
      background-color: rgba(#2463eb, .1);
  
    }
  }

  .selected {
    color: var(--color0);
    background-color: var(--clr-gray200);
  }
}

.footerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .ModalControls {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}

.modalCheckbox {
  width: 100%;
  padding: 0 0 1.5rem 0;
  background-color: var(--secondary-color);
  font-size: .9rem;
  display: flex;
  align-items: center;
  
}


.extendedMessages {
  tr, td {
    // padding-left: 1rem;
    // padding-right: 1rem;
  }
}

.translationWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .4rem;
  color: var(--clr-gray350);
  background-color: var(--clr-gray300);
  border-radius: 8px;
  max-width: fit-content; 
  padding: .3rem;
  margin: 3rem auto;

  span {
    display: flex;
    align-items: center;
    padding: .3rem;

    &:hover {
      cursor: pointer;
      color: var(--clr-gray500);
    }

    svg {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
  .active {
    color: var(--clr-gray400);
    background-color: var(--secondary-color);
    
    border-radius: 4px;
    box-shadow: var(--box-shadow);

  }
}

.loadMore {
  height: 42px;
  text-align: center;
  margin: 1rem auto;
  justify-content: center;
  font-weight: 500;
}

.bulkButtonsWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.08);

  &:hover {
    box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.08) inset;
  }
  

  button {
    background-color: var(--secondary-color);
    border: 1px solid var(--clr-gray300);
    color: inherit;
    border-radius: 0;
    border-right: none;
    transition: all .2s;
    font-size: .9rem;

    .clear {
      display: flex;
      align-items: center;
      color: var(--clr-gray350);
      
      &:hover {
        color: var(--color0);
        transform: scale(1.2);
        cursor: pointer;
      } 
    }

    &:hover {
      color: var(--color0);
      transform: translateY(-1px);
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.06);
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: var(--color0);
      transform: translateY(0);
      padding-right: .5rem;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-right: 1px solid var(--clr-gray300);

      &:hover {
        // color: var(--color2);
        color: var(--color0);
      }
    }
  }
  
}

.bulkButtonsWrapper.floating {
  position:fixed;
  right: 4rem;
  top: 100px;
  z-index: 1000;
}

.sentimentSelect {
  margin-bottom: 1.5rem;
}

label.label {
  font-size: .9rem;
  font-weight: 600;
  margin-bottom: .5rem;
  position: relative;


  .labelIcon {
    display: inline-block;
    align-items: center;
    position: relative;
    top: 6px;
    margin-right: 5px;
    
    svg {
      color: var(--clr-gray500);
      width: 22px;
      height: 22px;
    }
  }

  .subLabel {
    font-size: 13px;
    font-weight: 400;
    color: #767b91;
    margin-left: 2px;
  }
}


