.addToNarrativeModal {
  :global(.modal-dialog) {
    min-width: 600px;
   
    :global(.modal-content) {
      // height: 200px;
      .userNote {
        margin-top: .5rem;
        margin-bottom: 1.5rem;
        font-size: 1rem;
        font-weight: 500;
        // color: var(--clr-gray400);
      }

      .addToNarrativeModal__control {
        margin-bottom: 2rem;
      }

      .error {
        padding: 1rem 0.5rem;
        color: var(--color2);
        background-color: var(--error-light);
      }
      :global(#message-to-narrative-modal) {
        label {
          display: inline-block;
          margin-bottom: .5rem;
          font-size: 1rem;
        }
      }
    }
  }

  .create {
    display: flex;
    align-items: center;
    justify-content: left;
    color: var(--color0);
    font-weight: 500;
    gap: .5rem;
  }
}