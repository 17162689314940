.root {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 10rem;
  color: var(--text-headline);

  a:hover {
    cursor: pointer;
  }
}

.tagsContainer {
  // max-width: 440px;
  white-space: wrap;
}

.dualTag, .tag {
  display: inline-flex;
  flex-direction: row;
  border-radius: 5px;
  font-size: .7rem;
  font-weight: 500;
  margin-right: .3rem;
  margin-bottom: .2rem;

  .tagKey {
    background-color: var(--clr-gray300);
    padding: 3px 4px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  .tagValue {
    background-color: var(--clr-gray350);
    color: var(--secondary-color);
    padding: 3px 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

}

.tag {
  background-color: var(--clr-gray300);
  padding: 4px 6px;
}

.header, .card {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  background-color: var(--secondary-color);
  padding: 3rem 2rem;
  border-radius: 8px;
  border: 1px solid var(--clr-gray200);
  box-shadow: var(--box-shadow);

  .avatarWrapper {
    display: flex;
    align-items: start;

    .avatar {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      background-color: var(--clr-gray350);
      border: 4px solid var(--clr-gray200);
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 4rem;
      font-weight: 600;
       img {
        width: 8rem;
        height: 8rem;
       }
    }
  }

  .headerMain {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .headerName {
      font-size: 2rem;
      display: flex;
      align-items: center;
      gap: .7rem;
    }

    .sourceLink {
      a {
        color: var(--color0);
        text-decoration: underline;
        display: inline-block;
        transition: all .2s;

        &:hover {
          color: var(--active);
          transform: translateY(-1px);
        }
      }
    }

    .headerInfo {
      .headerList {
        columns: 2;
        -moz-columns: 2;
        -webkit-columns: 2;
        column-gap: 3rem;

        li {
          text-transform: uppercase;
          font-size: .8rem;
          letter-spacing: .5px;

          &:not(:last-child) {
            margin-bottom: .8rem;
          }

          span {
            color: var(--clr-gray350);
            margin-right: .3rem;
          }
        }
      }
    }
  }
}

.card {
  justify-content: center;
}

.metrics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3rem;

  .metric {
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: 1.5rem;
    border: 1px solid var(--clr-gray200);
    background-color: var(--secondary-color);
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: 2rem 1.5rem;
    flex: 1 0 0;

    .metricHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: .7rem;
      font-weight: 600;
      font-size: 1.2rem;
    }

    .metricIcon {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      display: flex;
      align-content: center;
      justify-content: center;

      &.blue {
        background-color: var(--color0);
      }

      &.red {
        background-color: var(--error-primary);
      }

      &.green {
        background-color: var(--success-primary);
      }
      
      svg {
        align-self: center;
      }
    }

    .metricData {
      font-weight: 600;
      font-size: 2.5rem;
    }
  }
}

.politics {
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: var(--box-shadow);

  .politicsList {
    color: inherit;
    text-transform: uppercase;
    letter-spacing: .4px;
    li {
      .listHeading{
        color: var(--clr-gray350);
      } 

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }

    a {
      display: inline-block;
      color: var(--text-headline);
      text-decoration: underline;
      transition: all .2s;
    
      &:hover {
        cursor: pointer;
        transform: translateY(-1px);
        color: var(--color0);
      }
    }
  }
}

.affiliation {
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: var(--box-shadow);

  .affiliationHeading {
    font-weight: 600;
  }

  .affiliationWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 3rem;

    .item {
      flex: 1 0 0;
      background-color: rgba(#f4f6fb, .9);
      padding: .5rem .7rem;
      border-left: 2px solid var(--clr-gray400);
      border-radius: 2px;

      .itemHeading {
        color: var(--clr-gray350);
        text-transform: uppercase;
        font-size: .7rem;
        letter-spacing: .4px;
        margin-bottom: .3rem;
      }

      a {
        display: inline-block;
        color: var(--text-headline);
        text-decoration: underline;
        transition: all .2s;
      
        &:hover {
          cursor: pointer;
          transform: translateY(-1px);
          color: var(--color0);
        }
      }

      p {
        font-weight: 500;
        display: inline-flex;
        align-items: center;

        > .tooltipControls {
          margin-left: .3rem;
        }
      }
    }
  }
}

.campaigns {
  .campaignsHeading {
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}

.cases {
  .narrativesHeading {
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;

    .tooltip {
      display: flex;
      align-items: center;
    }
    svg {
      margin-top: .2rem;
      color: var(--clr-gray350);
      width: 17px;
      height: 17px;
      vertical-align: middle;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .modalLink {
    font-weight: 500;
    font-size: 1rem;
    
    &:hover {
      color: var(--clr-gray400);
      cursor: pointer;

    }
  }

}

.tooltipControls {
  display: inline-flex;
  align-items: center;
  margin-top: 2px;
}

.sources {
  position: relative;
  .sourcesHeading {
    font-weight: 700;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;

    .tooltip {
      display: flex;
      align-items: center;
    }
    svg {
      margin-top: .1rem;
      color: var(--clr-gray350);
      width: 17px;
      height: 17px;
      vertical-align: middle;
      &:hover {
        cursor: pointer;
      }
    }
  }  

  .paginator {
    // margin-top: -0.5rem;
    width: 100%;
    padding-bottom: 1rem;
    border-top: 1px solid var(--clr-gray200);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.messages {
  .link {
    width: 15%;
    margin-right: 2rem;
  }

  td:first-of-type {
    min-width: 400px !important;
    white-space: nowrap;
  }
}

.anchor {
  margin-right: 1rem;
  text-transform: none;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    vertical-align: sub;
    margin-left: .4rem;

    &:hover {
      cursor: pointer;
    }
  }
  
}

.extendedMessages {
  // td:first-child {
  //   width: auto !important;
  // }

  td:first-child {
    width: 100% !important;
  }

  .extendedMessage {
    padding-right: 40px;
  }

  .textBlock {
    margin-left: 1rem;
    
  }
}

.searchIconWrapper {
  margin-right: 3px;

  svg {
    width: 24px;
    height: 24px;
    color: var(--clr-gray350);
  }
}

.findMoreContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;

  .moreButton {
    &:hover {
      background-color: var(--secondary-color);
      color: var(--color0);
      border: 1px solid var(--color0);
    }
  }
}

.tableWrapper {
  // overflow-x: auto;
  // overflow-y: auto;
  width: auto;
  // max-height: 60vh;
  // border-bottom: 1px solid var(--clr-gray300);
  // border-top: 1px solid var(--clr-gray300);
}

.linkedActors, .cases {
  background-color: var(--secondary-color);
  .controls {
    display: flex;
    flex-direction: row;
    gap: .5rem;

    :global(.new-button) {
      padding: 7px 14px;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid var(--active);
      }
    }
  }
  .sourceNameWrapper {
    display: flex;
    flex-direction: row;
    gap: .5rem;

    .platformWrapper {
      svg {
        width: 16px;
        height: 16px;
      }
    }

  }

  .sourceNameContainer {
    display: flex;
    flex-direction: column;
    gap: .4rem;
    line-height: 1;
    font-size: 1rem;
    font-weight: 500;

    .verifiedSource {
      svg {
        width: 15px;
        height: 15px;
        margin-left: 5px;
        margin-top: -2px;
      }
    }

    & > span  >  a {
      font-size: 1rem;
      color: var(--text-headline);

      &:hover {
        cursor: pointer;
        color: var(--clr-gray400);
      }
    }


    .sourceInfo {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: .3rem;
      font-size: .9rem;

      & >  a {
        font-size: .9rem;
        color: var(--clr-gray500);
        font-weight: 400;
      }

      .userName {
        color: var(--clr-gray500);
        font-weight: 500;
      }


      span {
        font-weight: 400;
        color: var(--clr-gray500);
        white-space: nowrap;
      }

      .active {
        color: var(--success-medium);
      }

      .inactive {
        color: var(--error-primary);
      }
    }
  }

  tr, td {
    border: 1px solid var(--clr-gray300);
  }

  td  {
    font-size: .9rem;
    vertical-align: top;
    width: fit-content;
    // white-space: nowrap; doesn't work in safari properly with table

    i {
      font-size: 1.2rem;
    }
  }

  td:first-of-type {
    // position: sticky;
    left: -1px;
    // background-color: var(--secondary-color);
    // border-top: none;
    white-space: wrap;
    min-width: 500px;
    // max-width: 600px;

    // &::after {
    //   content: '';
    //   width: 2px;
    //   height: 100%;
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   background-color: var(--clr-gray300);
    //   box-shadow: 3px 0px 2px -1px rgba(0,0,0,0.18);

    // }

  }

  // td:last-of-type {
  //   position: sticky;
  //   right: -1px;
  //   background-color: var(--secondary-color);
  //   // border-top: none;
  //   white-space: wrap;
  //   min-width: 50px;
  //   max-width: 150px;

  //   &::before {
  //     content: '';
  //     width: 2px;
  //     height: 100%;
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     background-color: var(--clr-gray300);
  //     box-shadow: 3px 0px 2px -1px rgba(0,0,0,0.18);

  //   }

  // }


  td:last-child {
    width: 1%;
  }

  td:first-child {
    width: 1%;
  }
}

.searchIconWrapper {
  margin-right: -1.2rem;
}

.noData {
  width: 100%;
  text-align: center;
  margin: 5rem auto
}

.originalTextBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  background-color: var(--clr-gray150);
  border-left: 6px solid var(--clr-gray300);
  padding: 1rem 1rem 1rem .5rem;
  margin-left: 11px;
  margin-right: 4rem;
  border-radius: 4px;

  .loaderContainer {
    width: 100%;
  }

  &:has(.loaderContainer) {
   align-items: center;
  }
  
  .extendedMessage {
    margin-left: 0;
    font-size: 1rem;
  }
}

.imagesContainer {
  margin-left: .8rem;
}


// .tooltip {
//   font-size: .8rem;
//   z-index: 100;
//   text-transform: none;
// }
