.label {
  display: block;
  margin-bottom: .3rem;
}

.root {
  input[type=number] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }

  .errorMessage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    border-radius: 4px;
    width: 100%;
    padding: 1rem;
    color: var(--color2);
    background-color: rgba(#ee6c74, .4);

    span {
      display: flex;
      align-items: center;
      color: var(--color2);

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}

