.search {
  //width: 100%;
  margin-left: 12px;
  background-color: rgba(160, 163, 166, 0.2);
}

.tagsContainer {
  // max-width: 440px;
  white-space: wrap;
}

.dualTag, .tag {
  display: inline-flex;
  flex-direction: row;
  border-radius: 5px;
  font-size: .7rem;
  font-weight: 500;
  margin-right: .3rem;
  // margin-bottom: .2rem;

  .tagKey {
    background-color: var(--clr-gray300);
    padding: 3px 4px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  .tagValue {
    background-color: var(--clr-gray350);
    color: var(--secondary-color);
    padding: 3px 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

}

.tag {
  background-color: var(--clr-gray300);
  padding: 4px 6px;
}

h3 {
  margin: 1rem 0;
}

.url {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}

.noData {
  width: 100%;
  text-align: center;
  margin: 5rem auto
}

.messages {
  
  a {
    svg {
      width: 15px;
      height: 15px;
      margin-left: 5px;
    }
  }

  .sourceNameWrapper {
    display: flex;
    flex-direction: row;
    gap: .5rem;
  
    .platformWrapper {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  
  }
  
  .sourceNameContainer {
    display: flex;
    flex-direction: column;
    gap: .4rem;
    line-height: 1;

    span > a {
      font-size: 1rem;
    }


    .sourceInfo {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: .3rem;

      & >  a {
        font-size: .9rem;
      }
  

      span {
        font-weight: 400;
        color: var(--clr-gray500);
      }

      .active {
        color: var(--success-medium);
      }

      .paused {
        color: var(--warning-primary);
      }

      .archived {
        color: var(--error-primary);
      }
    }
  
  
    span {
      font-weight: 500;
  
      svg {
        width: 15px;
        height: 15px;
        margin-left: 5px;
        margin-top: -2px;
  
      }
  
      a {
        color: var(--text-headline);
        font-size: 1rem;
  
        &:hover {
          color: var(--clr-gray400);
        }
      }
  
    }
  
    a {
      font-size: 1rem;
    }
  }
  
  tr, td {
    border: 1px solid var(--clr-gray300);
  }
  
  td  {
    font-size: .9rem;
    vertical-align: top;
    width: fit-content;
    white-space: nowrap;
  
    i {
      font-size: 1.2rem;
    }
  }

  .controls {
    width: 1%;

    :global(a.new-button) {
      border: 1px solid var(--color0);
    }

    .controlsContainer {
      display: flex;
      flex-direction: row;
      gap: .3rem;

      :global(a.new-button) {
        border: 1px solid var(--color0);
      }

      :global(.btn-danger) {
        border: 1px solid var(--error-primary);
        max-height: 35px;
        max-width: 50px;

      }
    }
  }

  
  td:first-of-type {
    // position: sticky;
    // left: -1px;
    background-color: var(--secondary-color);
    // border-top: none;
    white-space: wrap;
    max-width: 700px;
  
    // &::after {
    //   content: '';
    //   width: 2px;
    //   height: 100%;
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   background-color: var(--clr-gray300);
    //   box-shadow: 3px 0px 2px -1px rgba(0,0,0,0.18);
  
    // }
    
  }

  .mainInfo {
    min-width: 600px;
  }
  
}




