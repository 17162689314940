.root {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 250ms ease;

  &:hover {
    opacity: 0.8;
  }
}
