.dashboards {
  td, td:first-child {
    vertical-align: top;
    width: fit-content;
  }
}

.noData {
  margin: 4rem auto;
  font-weight: 500;
  text-align: center;
}
