.extendedMessages {
  // background-color: var(--secondary-color);
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  position: relative;

  .headerInfo {
    vertical-align: middle;
    &:has(.selectAllContainer) {
      justify-items: center;
    }
    span {
      margin-right: 2rem;
    }
    .selectAllContainer {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      span {
        margin-right: .5rem;
        font-weight: 400;
      }

      .selectAllMessages {
        color: var(--color0);
        font-weight: 400;
        margin: 0;
        &:hover {
          cursor: pointer;
          text-decoration: underline var(--color0);
        }
      }
    }
  }

  a {
    display: flex;
    align-items: center;
  }

  a:hover {
    svg {
      transition: all .2s;
    }
    svg:hover {
      color: var(--color0);
      transform: translateY(-1px);
    }
  }

  thead {
    th, td {
      background-color: var(--clr-gray300);
      display: flexbox;
      align-items: baseline;
    }
    td:last-child {
      width: 2%;
    }

  }

  th, td {
    background-color: white;
    padding: 1rem .7rem;
    border-bottom:1px solid var(--clr-gray300); 
  }

  td:first-child {
    width: auto;
  }

  @media print {
    tr {
      break-inside: avoid;
    }
  }
}

:global(.card-messageswraper) {
  .extendedMessages {
    overflow: auto;
  }
}

:global(.modal-content) {
  .extendedMessages {
    border-radius: 0;
  }
}

.messageInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  border-bottom: 1px solid var(--clr-gray300);

  .modalIcon {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;

    .totalDeduplicated {
      text-transform: uppercase;
      font-size: .9rem;
      font-weight: 500;
      color: var(--clr-gray350);
      white-space: nowrap;

      .totalDeduplicatedValue {
        color: var(--clr-gray500);
        margin-left: .4rem;
      }
    }
    svg {
      color: var(--clr-gray350);
      margin-bottom: .5rem;

      &:hover {
        color: var(--color0);
        cursor: pointer;
      }
    }
  }
}

.extendedMessages.pdfMode {
  .messageInfo {
    gap: 1.5rem;
  }
  margin-bottom: 1rem;
  max-width: 911px;

  .messageContainer {
    max-width: 790px;
  }

  .cutText {
    max-width: 100px;
    min-width: 20px;
  
  }
}

.messageInfo {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  padding: 0rem .7rem .7rem .7rem;
  // border-bottom: 1px solid var(--clr-gray300);
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    line-height: 1rem;
    font-weight: 500;
    font-size: .9rem;

    a {
      display: flex;
      align-items: center;
      svg {
        &:hover {
          color: var(--color0);
        }
      }
    }

    svg {
      width: 20px;
      height: 20px;
      color: var(--clr-gray350);

      &:hover {
        cursor: pointer;
        color: var(--clr-gray400);
      }
    }
  }

  .platform {
    span:first-child {
      margin-right: 1rem;
    }
  }

  .date {
    display: flex;
    line-height: 1.2;
    gap: .5rem;
    white-space: nowrap;
  }

  .contentType {
    svg {
      width: 28px;
      height: 28px;
    }

  }

}

.favorites {
    padding-top: 3px;

    :hover {
      cursor: pointer;
      svg {
        color: var(--color0);
      }
    }
    span {
      svg {
        color: var(--clr-gray350);
      }
    }
    
  }

.messageContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}

.messageMetricsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1rem;
}

.textBlock {
  position: relative;
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  .loaderContainer {
    width: 100%;
  }

  .similarMessagesSearch {
    svg {
      color: var(--clr-gray350);
      width: 26px;
      height: 24px;
    }
  }
}

.originalTextBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  background-color: var(--clr-gray150);
  border-left: 6px solid var(--clr-gray300);
  padding: 1rem 1rem 1rem .5rem;
  margin-right: 4rem;
  border-radius: 4px;

  .loaderContainer {
    width: 100%;
  }

  &:has(.loaderContainer) {
   align-items: center;
  } 
}



.messageMetrics {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding-left: .7rem;
  justify-content: space-between;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;

    svg, .anchor svg {
      margin-right: .5rem;
      color: var(--clr-gray350);
      width: 26px;
      height: 24px;

      &:hover {
        cursor: pointer;
        color: var(--clr-gray400);
      }
    }
  }

  .positive, .neutral, .negative {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .2rem .1rem;
    padding-left: .1rem;
    border-radius: 4px;

    svg {
      margin: 0;
    }
  }

  .positive {
    background-color: var(--success-light);
    
    svg {
      color: var(--success-primary)!important;
    }
  }
  
  .negative {
    background-color: var(--error-light);

    svg {
      color: var(--error-primary)!important;
    }
    
  }
  
  .neutral {
    background-color: var(--warning-light);
    svg {
      color: var(--warning-primary)!important;
    }
    
  }  
}

.extendedMessage {
  font-size: 1rem;
  padding-right: 40px;
}

.cutText {
  min-width: 40px;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  a {
    display: inline-block;
    text-decoration: underline;
    transition: all .2s;

    &:hover {
      color: var(--color0);
      transform: translateY(-1px);
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.sourceLink {
  a {
    display: inline-block;
    text-decoration: underline;
    transition: all .2s;

    &:hover {
      color: var(--color0);
      transform: translateY(-1px);
    }
  }

  &:hover {
    cursor: pointer;
  }
}


.controls {
  height: inherit;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;

  .approve, .delete {
    padding: 5px 7px;
    min-width: 40px;
    display: flex;
    justify-content: center;
  }

  button:global(.btn, .btn-danger) {
    // padding: 5px 10px;
    border: none;

    &:disabled {
      padding: 5px 10px;
    }
  }
}

.anchor {
  display: flex;
  align-items: center;
  text-transform: none;
  gap: .5rem;
  span {
    margin-left: .5rem;
  }
}

.status {
  background-color: var(--clr-gray300);
  padding: .3rem .7rem;
  border-radius: 15px;
  text-align: center;

  &.new {
    color: var(--color0);
    background-color: rgba(#2463eb, .2);
  }

  &.deleted {
    color: var(--error-primary);
    background-color: rgba(#EF4444, .2);
  }

  &.approved {
    color: var(--clr-gray450);
    background-color: var(--clr-gray200);
  }
  
}

.translationWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .4rem;
  color: var(--clr-gray350);
  background-color: var(--clr-gray300);
  border-radius: 8px;
  max-width: fit-content; 
  padding: .3rem;
  margin: 3rem auto;

  span {
    display: flex;
    align-items: center;
    padding: .3rem;

    &:hover {
      cursor: pointer;
      color: var(--clr-gray500);
    }

    svg {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
  .active {
    color: var(--clr-gray400);
    background-color: var(--secondary-color);
    
    border-radius: 4px;
    box-shadow: var(--box-shadow);

  }
}

.translationContainer {
  margin-bottom: 2rem;
  .translationWrapper {
    margin: auto 0;
  }
}

.noData {
  margin: 4rem auto;
  font-weight: 500;
  text-align: center;
}

.error {
  color: var(--error-primary);
}

.messageModal {
  :global(.modal-dialog) {
    max-width: 1100px;
    min-width: 1000px;
  }

  .messageInfo {
    border-bottom:  none;
    padding: .7rem;
    padding-bottom: 0;
  }

  .messageText {
    padding-top: 2rem;
    display: block;
    min-height: fit-content;

    p:not(:first-child) {
      margin-top: 1rem;
    }
  }

  :global(.modal-footer) {
    justify-content: end;
  }

  .metrics-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .messageMetrics {
    span {
      svg, .anchor svg {
        &:hover {
          cursor: pointer;
          color: var(--clr-gray400);
        }
      }
    }
  }
}

.loadMore {
  height: 42px;
  text-align: center;
  margin: 1rem auto;
  justify-content: center;
  font-weight: 500;
}

.sourceActorWrapper span {
  div {
    gap: 0;
  }
  .externalLink {
    margin-left: 20px;
  }

  @media print {
    .externalLink {
      display: none !important;}
  
  }
}

:global(.modal-content) {
  .cutText {
    max-width: 110px;
  }
}

.imagesContainer {
  margin-top: 1rem;
}

@media only screen and (max-width: 1580px) {
  .cutText {
    max-width: 160px;
  }
}

@media only screen and (max-width: 1490px) {
  .cutText {
    max-width: 110px;
  }

  .messageInfo {
    gap: 2rem;
  }
}

@media only screen and (max-width: 1380px) {
  .messageInfo {
    gap: 1rem;
  }
}

@media print {
  .extendedMessages {
    overflow: visible;
    filter: opacity(1);
    -webkit-filter:opacity(1);
  }
  .status {
    display: none !important;}
    .textBlock {
      break-inside: avoid;
    }
}

