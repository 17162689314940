.copy-group-wrapper {
    min-height: 200px;
}

.copy-group-wrapper > div {
    margin-bottom: 1rem;
}

.css-26l3qy-menu.copy-group-modal__menu {
    z-index: 99999;
    position: inherit;
}
