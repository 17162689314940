.switch-small {
  position: relative;
  display: inline-block;
  /* width: 48px;
  height: 26px; */
  width: 30px;
  height: 16px;
}

/* Hide default HTML checkbox */
.switch-small input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider-small {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-small:before {
  position: absolute;
  content: '';
  height: 11px;
  width: 11px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider-small {
  background-color: #2196f3;
}

input:checked + .slider-small.red {
  background-color: var(--color2);
}


input:focus + .slider-small {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider-small:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(13px);
}

.slider-small.round {
  border-radius: 17px;
}

.slider-small.round:before {
  border-radius: 50%;
}
