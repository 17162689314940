.opinionCardContainer {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  border: 2px solid transparent;
  background-color: var(--secondary-color);
  margin-bottom: 1rem;

  &:hover {
    .opinionControls {
      display: flex;
    }
  }
}

.adminCheckbox {
  display: none;
}


.storyCardModal {
  :global(.modal-dialog) {
    min-width: 900px;
  }

  .modalForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}


.opinionControls {
  display: none;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  justify-content: center;

  .adminCheckbox {
    display: flex;
    transform: scale(2);
    align-self: center;
    margin-right: 0;
    margin-bottom: .2rem;
  }

  .adminCheckbox.adminCheckboxChecked {
    display: flex;
    transform: scale(2);
    align-self: center;
  }

  .opinionCardButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: .2rem .3rem;
    border-radius: 4px;
    font-size: .8rem;
    color: var(--clr-gray500);
    background-color: var(--secondary-color);
    transition: background-color 250ms ease, color 250ms ease;
    border: 1px solid var(--clr-gray350);

    svg {
      width: 17px;
      height: 17px;
    }

    &:hover {
      background-color: var(--color0);
      color: var(--secondary-color);
      border: 1px solid var(--color0);
    }
  }
}

.opinionControls:has(.adminCheckbox.adminCheckboxChecked) {
  display: flex;
}



.opinionCardTitle {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 500;
  color: var(--main-color);
  white-space: wrap;
  word-wrap: break-word;
  width: 70%;
  &:hover {
   cursor: pointer;
  }
}

.opinionCardInfo {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30%;
}

.opinionCardInfo:has(.opinionCardMessagesContainer) {
  justify-content: flex-end;
  gap: 3rem;
  width: 25%;
}

.opinionCardRatingContainer, .opinionCardSourcesContainer, .opinionCardOpinionBalanceContainer, .opinionCardSentimentContainer, .opinionCardMessagesContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: .4rem;

  .opinionCardHint {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .2rem;
    line-height: 1.1;

    .opinionCardHintText {
      color: var(--clr-gray350);
      font-size: .8rem;
    }

    .opinionCardHintIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        width: 15px;
        height: 15px;
        color: var(--clr-gray300);
      
      }
    }
  }
}

.opinionCardRatingContainer, .opinionCardMessagesContainer {
  .opinionCardRating, .opinionCardMessages {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    font-size: 1.2rem;
    font-weight: 600;

    .opinionCardMessagesValue {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.55rem;
    }

    .opinionCardRatingValue, .opinionCardRatingIcon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  } 
}

.opinionCardSentimentContainer {
  .opinionCardSentiment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    font-size: .9rem;
    font-weight: 500;
    padding: .3rem .4rem;
    border-radius: 4px;

  }

  .positive {
    color: var(--success-medium);
    background-color: var(--success-light);
  }

  .negative {
    color: var(--error-primary);
    background-color: var(--error-light);
  }

  .neutral {
    color: var(--warning-primary-dark);
    background-color: var(--warning-light);
  }
}

@media only screen and (max-width: 1441px) {

  .opinionCardInfo {
    width: 35%;
  }

  .opinionCardTitle {
    width: 65%;
  }
}