

.iconActive path {
  fill: var(--color0);
}

.icon {
  fill: #A0A3A6;
}

.chevron-icon {
  rotate: 180deg;
  transform: translateY(0) scale(1) rotate(0deg) .2s;

  &:hover {
    cursor: pointer;
    transform: translateY(-1px) scale(1.1) rotate(180deg);
  }
}

.open {
  transform: rotate(180deg);

  &:hover {
    cursor: pointer;
    transform: translateY(1px) scale(1.1) rotate(0deg);
  }
}

.chooser {
  font-weight: 500;

  .control {
    padding: .5rem .8rem;
    color: var(--color0);
    font-size: .8rem;

    &:hover {
      cursor: pointer;
    }
  }

  ul {
    list-style: none;

    li {
      display: flex;
      align-items: flex-start;
      padding: .5rem .5rem;
      margin: 0 .3rem;
      border-radius: 5px;
      line-height: 1.1;
      font-size: .75rem;

      &:hover {
        background-color: #EFF6FF;
        color: var(--color0);
      }
    }
  }

  .select-filter {
    font-size: .7rem;
    width: 95%;
    margin: 0 auto;

    &__menu {
      z-index: 1000;
      
    }
    &__menu-list {
      scrollbar-color: var(--color0);

      &::-webkit-scrollbar {
        background: var(--secondary-color);
        width: 4px;
        height: 4px;
      }
    
      &::-webkit-scrollbar-thumb {
        background: var(--color0);
        border-radius: 2px;
      }
    
      &::-webkit-scrollbar-track {
        border-radius: 2px;
      }
    }
  }
}


.source-search-filter, .country-search-filter, .sentiment-chooser, .affiliations-search-filter, .bot-chooser, .group-search-filter, .language-search-filter {
  margin-top: 1rem;
  margin-bottom: 1rem;
  
  .select-filter__menu {
    display: contents;
  }
}

.affiliations-search-filter {
  margin-top: 0rem;

  .affiliation-filter-controls-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: .5rem;
    padding-right: 1rem;
  }
}

input[type=checkbox] {
  margin-right: .5rem;
}

