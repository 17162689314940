.root {
  background: none;
  border: none;
  outline: none;
  font-family: inherit;
  font-weight: inherit;
  padding: 0;
  color: var(--text-headline);

  &:focus-visible {
    outline: #015ecc auto 1px;
  }
}

.root mark {
  background-color: #d7e8fd;
  border: 1px solid #88b2f8;
  border-radius: 4px;
  padding: 1px 4px 1px 20px;
  white-space: nowrap;
  position: relative;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
  }

  &[data-type='keyword'] {
    background-color: #d7e8fd;
    border: 1px solid #88b2f8;
  }
  &[data-type='keyword']::before {
    content: url(./assets/keyword.svg);
  }

  &[data-type='persona'] {
    background-color: #e7fac3;
    border: 1px solid #acdf5b;
  }
  &[data-type='persona']::before {
    content: url(./assets/persona.svg);
  }

  &[data-type='organization'] {
    background-color: #f0e4fe;
    border: 1px solid #c997f9;
  }
  &[data-type='organization']::before {
    content: url(./assets/organization.svg);
  }

  &[data-type='location'] {
    background-color: #fddede;
    border: 1px solid #f48d8e;
  }
  &[data-type='location']::before {
    content: url(./assets/location.svg);
  }

  &[data-type='military-unit'] {
    background-color: #c9f6e2;
    border: 1px solid #6ed3b2;
  }
  &[data-type='military-unit']::before {
    content: url(./assets/military-unit.svg);
  }
}

.text {
  font-weight: inherit;
  line-height: 20px;
  font-size: 1rem;
  padding: .2rem 0;
  overflow-wrap: break-word;
  

  white-space: pre-wrap;
  text-align: left;

  display: block;
  height: fit-content;
  overflow: hidden;

  p:not(:first-child) {
    margin-top: 1rem;
  }
}

.label {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

