.narratives {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  /* display: flex; */
  /* justify-content: space-between; */
  gap: 1rem;
  flex: 1;
  width: 100%;
}

.extendedMessage {
  margin-left: .9rem;
}

.searchIconWrapper {
  margin-right: -1rem;
}

.narratives .narrative {
  background: var(--secondary-color);
  color: var(--main-color);
  /* border-radius: 10px; */
  /* box-shadow: 0 2px 10px var(--clr-gray300); */
  margin-bottom: 1rem;
  overflow: hidden;

  border: 1px solid var(--clr-gray200);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

.narrative .header {
  padding: 1rem;
  display: flex;
  flex: 1;
  align-items: center;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom: 1px solid var(--clr-gray200);
  color: var(--stroke);
  background: var(--background);
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  padding-right: 20px;
  overflow: hidden;
}

.header__number {
  border: 1px solid var(--stroke);
  border-radius: 4px;
  width: 50px;
  text-align: center;
  font-size: 12px;
  padding: 2px 0;
  line-height: 1;
  margin-right: 1em;
}

.narrative .body {
  padding: 1rem;
}

.narrative .body .metrics {
  display: flex;
  font-size: 20px;
  flex-wrap: wrap;
}
.narrative .percentage {
  padding: 0.5em 0 0.5em 0;
}
.metrics .card-content {
  width: 50%;
  margin-bottom: 16px;
}

.card .card-messageswraper {
  overflow: auto;
}

.card-content {
  display: flex;
  align-items: center;
}

.card-content__icon {
  width: 48px;
  height: 48px;
  border-radius: 9px;
  background: #eff6ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background: var(--background);
}
.card-content__icon-small {
  width: 36px;
  height: 36px;
}

.overview {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

.overview .card-wrapper {
  background: var(--secondary-color);
  color: var(--main-color);
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;

  border: 1px solid var(--clr-gray200);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06),
    0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

.overview .card-content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 50%;
}

.card-content .header {
  font-size: 0.8rem;
  text-transform: uppercase;

  color: var(--clr-gray350);
  font-size: 11px;
}

.card-content .body {
  font-size: 24px;
}

.list-content h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.color0 {
  --stroke: var(--color0);
  --background: var(--color0l);
}

.color1 {
  --stroke: var(--color1);
  --background: var(--color1l);
}

.color2 {
  --stroke: var(--color2);
  --background: var(--color2l);
}

.color3 {
  --stroke: var(--color3);
  --background: var(--color3l);
}

.color4 {
  --stroke: var(--warning-medium);
  --background: var(--warning-light);
}

.color5 {
  --stroke: var(--purple-primary);
  --background: var(--purple-light);
}

.color6 {
  --stroke: var(--pink-primary);
  --background: var(--pink-light);
}

.color7 {
  --stroke: var(--info-primary);
  --background: var(--info-light);
}

.card-content__icon path {
  stroke: var(--stroke);
}

.cardControlls {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: .5rem;
}

.report-section {
  .report-section__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .report-section__controls {
      justify-content: space-between;
      align-items: center;
      gap: .3rem;
      display: none;

      .report-control-button {
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: #fff;
        background-color: #2563eb;
        padding: 4px 6px;
        border: 1px solid transparent;

        &:hover {
          background-color: #fff;
          color: var(--color0);
          border: 1px solid var(--color0);
        }
      }

      .report-control-button.danger {
        background-color: var(--error-primary);
        &:hover {
          background-color: #fff;
          color: var(--error-primary);
          border: 1px solid var(--error-primary);
        }
      }
    }


  }

  .report-note-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: .5rem;
    font-size: 1rem;
    padding: 1rem;
    color: var(--main-color);
    border: 1px solid var(--clr-gray300);
    border-radius: 8px;
    background-color: var(--clr-gray200);

    & > ol li{
      list-style-type: decimal;
      list-style-position: inside;
    }

    & > ul li{
      list-style-type: disc;
      list-style-position: inside;
    }

  }

  .report-note-container.note-position-top {
    margin-bottom: -4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
  }

  .report-note-container.note-position-bottom {
    margin-top: -20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none;
    margin-bottom: 1rem;
  }

  
}

.report-control-button {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  background-color: #2563eb;
  padding: 4px 6px;
  border: 1px solid transparent;

  &:hover {
    background-color: #fff;
    color: var(--color0);
    border: 1px solid var(--color0);
  }
}

.report-control-button.danger {
  background-color: var(--error-primary);
  &:hover {
    background-color: #fff;
    color: var(--error-primary);
    border: 1px solid var(--error-primary);
  }
}

@media print {
  .sidebar,
  .controls,
  /* .card-circlechartwrapper, */
  .card-messageswraper,
  .pagination {
    display: none !important;
  }
  .main-content {
    margin-left: 0;
  }
  main {
    left: 0 !important;
    position: static !important;
    margin: 0 !important;
    width: auto !important;
  }
  .card {
    break-inside: avoid;
  }

  .report-note-container {
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }

  .card-wrapper {
    filter: opacity(1);
    -webkit-filter:opacity(1);
  }
  .card-content__icon {
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }
  .main-content > header {
    display: none;
  }
  .list-content h3 {
    margin-top: 0.5em;
  }
  .report-section {
    break-inside: avoid;
  }
  .breadcrumb span:first-child {
    display: none;
  }
  .breadcrumb span {
    font-size: 3em;
  }
  .page-header {
    margin-bottom: 0;
  }
  .breadcrumb span:before {
    display: none;
  }

  .narratives .narrative {
    filter: opacity(1);
    -webkit-filter:opacity(1);
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
  }
}

.chart-select {
  margin-left: auto;
  position: relative;
}

.noData {
  display: flex;
  height: calc(100vh - 10rem);
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
}
