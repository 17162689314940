.trendchart {
  overflow: visible;
}

// .trendchart .grid line {
//   stroke: #e7e8e9;

//   stroke-opacity: 0.7;
//   shape-rendering: crispEdges;
// }

// .trendchart .tick {
//   color: #a0a3a6;
// }

// .trendchart .grid path {
//   stroke-width: 0;
// }

canvas, img {
  image-rendering: optimizeQuality;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}
