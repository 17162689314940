.root {
  display: flex;
  height: 100%;
  justify-content: center;
  align-content: center;
  min-height: calc(80vh - 5rem);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  margin: 0.5rem;
  animation: scaling 2.5s ease-in-out infinite;
}

.circle:nth-child(0) {
  animation-delay: 0s;
}

.circle:nth-child(1) {
  animation-delay: 0.2s;
}

.circle:nth-child(2) {
  animation-delay: 0.4s;
}

.circle:nth-child(3) {
  animation-delay: 0.6s;
}

.circle:nth-child(4) {
  animation-delay: 0.8s;
}

.circle:nth-child(5) {
  animation-delay: 1s;
}

@keyframes scaling {
  0% {
    transform: scale(0.2);
    background-color: #2463eb;
  }
  40% {
    transform: scale(1);
    background-color: #40b870;
  }
  50% {
    transform: scale(1);
    background-color: #40b870;
  }
  100% {
    transform: scale(0.2);
    background-color: #2463eb;
  }
}
