.root {
  height: 100%;
  border-radius: 4px;
  position: relative;

  &.invalid {
    box-shadow: 0 0 2px 0 crimson;
  }

  .errorMessage {
    margin-bottom: 1rem;
    color: crimson;
  }

  .iconWrapper:hover {
    .icon {
        transform: scale(1.1);
        color: var(--color0);
    }
  }

  .icon {
    display: inline-block;
    padding-left: 12px;
    font-size: 1.4rem;
    color: var(--clr-gray350);
    width: 36px;
    height: 36px;
    opacity: 1;
    z-index: 40;
    position: absolute;
    top: .2rem;
    transition: all .2s;
  }
}

.input {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  outline: none;
  font-size: 1.1rem;
  border: 1px solid #ccc;
  background-color: var(--secondary-color);
  position: relative;

  &::placeholder {
    //color: #a0a3a6;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }

  &:global(.ql-disabled) {
    cursor: default;
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
    border-color: rgba(118, 118, 118, 0.3);
  }

  &:global(.ql-disabled) p {
    cursor: default;
  }

  &:global(.ql-container) {
    padding: 0;
    border-radius: inherit;
    border: 1px solid var(--clr-gray300);
    position: relative;
  }

  &:global(.ql-editor) {
    padding: .5rem 2rem .5rem 1rem;
  }

  :global(.ql-editor) p {
    margin-right: 2rem;
  }
}

.close {
  position: absolute;
  right: 1rem;
  top: .8rem;
  z-index: 40;
  transform: all .2s;
  color: var(--clr-gray400);

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
    color: var(--color0);
  }
}

.searchClose {
  position: absolute;
  right: 1rem;
  top: .5rem;
  z-index: 40;
  color: var(--clr-gray400);
  transform: all .2s;

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
    color: var(--color0);
  }
}



 

.search {
  &:global(.ql-container) {
    padding: 0 1rem;
    border-radius: inherit;
    border: 1px solid var(--clr-gray300);
    position: relative;
  }

  &:global(.ql-container) {
    :global(.ql-editor) {
      padding: .5rem 2rem .5rem 2rem ;
    }
  }

  :global(.ql-editor) p {
    margin-right: 0;
  }
}


.dropdown {
  margin-top: -5px;
  border-radius: 0 0 4px 4px;
  padding: 12px 16px;
  width: 100%;
  background-color: #fff;
  color: #1d1d1d;
  border: 1px solid var(--clr-gray300);

  animation: Drop 300ms ease;
  display: none;

  &.visible {
    display: block;
  }

  &.invalid {
    border-color: crimson;
  }
}

.heading {
  span {
    font-size: .9rem;
    font-weight: 600;
    margin-right: .5rem;
  }

  span:last-child {
    color: var(--clr-gray400);
    font-weight: 400;
  }

  a {
    text-decoration: underline;
    color: currentColor;
    &:hover {
      color: var(--color0);
    }
  }
}

.suggestions {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  margin-top: 1rem;
}

.operatorGroup {
  display: flex;
  gap: 8px;
  // margin-top: 1rem;
}

.suggestion {
  white-space: nowrap;
  text-align: center;
  padding: .2rem .5rem;
  background-color: var(--color0);

  &:hover {
    filter: brightness(110%) opacity(.9);
  }
}


@keyframes Drop {
  from {
    transform: translateY(-4px);
  }

  to {
    transform: translateY(0);
  }
}
