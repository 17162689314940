.label {
  display: block;
  margin-bottom: .4rem;
}

.enable2FAContainer {
  display: flexbox;
  flex-direction: row;
  justify-content: space-between;

  & > ul {


    margin: 1rem;
    list-style-type: circle;
    list-style-position: inside;
    & > li {
      list-style-position: inside;
      list-style: decimal;

      :global(input#code) {
        margin-top: 1.5rem;
      }

      & a {
        color: var(--active);
      }
    }
  }

  .QRCodeContainer {
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.successMessage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  border-radius: 4px;
  width: 100%;
  padding: 1rem;
  background-color: var(--success-light);
  color: var(--success-medium);

  span {
    display: flex;
    align-items: center;
    color: var(--color2);

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
}


.errorMessage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  border-radius: 4px;
  width: 100%;
  padding: 1rem;
  color: var(--color2);
  background-color: rgba(#ee6c74, .4);

  span {
    display: flex;
    align-items: center;
    color: var(--color2);

    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
  }
}

.codeForm {
  max-width: 550px;
  margin: 25px 0;
  
  input {
    margin: 0 5px;
    text-align: center;
    line-height: 30px;
    font-size: 50px;
    border: solid 1px var(--clr-gray300);
    // box-shadow: 0 0 5px #ccc inset;
    box-shadow: var(--box-shadow);
    outline: none;
    width: 11%;
    transition: all .2s ease-in-out;
    border-radius: 3px;
    
    &:focus {
      border-color: var(--info-light);
      box-shadow: 0 0 5px var(--info-light) inset;
    }
    
    &::selection {
      background: transparent;
    }
  }
}

