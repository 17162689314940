.summaryPanel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: var(--box-shadow);
  border: 1px solid var(--clr-gray200);
  border-radius: 8px;
  margin-bottom: 2rem;
  overflow: hidden;
  background-color: var(--secondary-color);

  .summaryPanelCards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .summaryPanelBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--clr-gray100);
  }

  .center {
    justify-content: space-around;
  }

}
.summaryPanel.loader {
  height: 100px;
  
}

:global(.widget-container) {
  .summaryPanel {
    margin-bottom: 1rem;
    // .summaryPanelBody {
    //   background-color: var(--clr-gray150);
    // }
  }
}

.summaryMainCard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  flex: 1 0;
  cursor: pointer;

  .summaryCardContainer {
    display: flex;
    justify-content: start;
    gap: 1.5rem;


  }


  .summaryMainCardHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: .5rem;

    .summaryMainCardIcon {
      display: flex;
      justify-content: center;
      align-items: center;
    
    }
  }

  .summaryMainCardTitle {
    font-size: .8rem;
    font-weight: 400;
    color: var(--clr-gray350);
    text-transform: uppercase;
    white-space: nowrap;
  }

  .summaryMainCardBody {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    font-size: 1.35rem;
    font-weight: 500;
    color: var(--main-color);
  }
}

.summaryMainCard:not(:last-child) {
  border-right: 1px solid var(--clr-gray200);
}

.summaryMainCard:not(:first-child) {
  border-left: 1px solid var(--clr-gray200);
}

.active {
  position: relative;
  .summaryMainCardTitle, .summaryMainCardData {
    color: var(--color0);
  }

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--color0);
    bottom: 0;
    left: 0;
  }
}

.summarySmallCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 1rem;
  gap: .4rem;

  .summarySmallCardTitle {
    font-size: .8rem;
    font-weight: 400;
    color: var(--clr-gray400);
    text-transform: uppercase;
  }

  .summarySmallCardData {
    font-size: 1.35rem;
    font-weight: 500;
    color: var(--main-color);
  }

}

.summarySmallCard.clickable {
  &:hover {
    cursor: pointer;
    .summarySmallCardTitle, .summarySmallCardData {
    color: var(--color0);
    }
  }
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--clr-gray400);
}

@media only screen and (max-width: 972px) {
  .summaryPanel {
    .center {
      justify-content: space-between;
    }
  }
  
  .summaryMainCardTitle {
    font-size: .7rem;
    font-weight: 400;
    color: var(--clr-gray350);
    text-transform: uppercase;
  }

  .summaryMainCardBody {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--main-color);
  }

  .summarySmallCard {
    .summarySmallCardTitle {
      font-size: .7rem;
      font-weight: 400;
      color: var(--clr-gray400);
      text-transform: uppercase;
    }
  
    .summarySmallCardData {
      font-size: 1.25rem;
      font-weight: 500;
      color: var(--main-color);
    }
  }

}

@media print {
  .summaryPanel {
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
    filter: opacity(1);
    -webkit-filter:opacity(1);

    .center {
      justify-content: space-between;
    }
  }

  .summarySmallCard {
    .summarySmallCardTitle {
      font-size: .6rem;
      font-weight: 400;
      color: var(--clr-gray400);
      text-transform: uppercase;
    }
  
    .summarySmallCardData {
      font-size: 1.25rem;
      font-weight: 500;
      color: var(--main-color);
    }
  }

  
}
