.avatarGroup {
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: center;
}
.avatarLetter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    color: var(--main-color);
    font-weight: 600;
    font-size: 12px;
    box-shadow: 0 .25rem 1rem rgba(0, 0, 0, .1);
    border: 2px solid var(--clr-gray200);
}
.avatar, .avatarLetter {
    position: relative;
    transition: .2s;
}
.avatar:not(:first-child), .avatarLetter:not(:first-child){
    margin-left: -.65rem;
}
.avatar:hover, .avatarLetter:hover {
    z-index: 1;
    transform: translateY(-.5rem);
}

.avatar img {
    width: 26px;
    height: 26px;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 .25rem 1rem rgba(0, 0, 0, .1);
    background-color: #fff;
}

.tooltipContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;

    svg {
        width: 1rem;
        height: 1rem;
    }
}