
.root {
  position: relative;
}

.button {
  white-space: nowrap;
  border: 1px solid var(--clr-gray300);
  padding: .5rem 1rem;
  border-radius: 4px;

  &:hover {
    filter: brightness(105%);
  }
  
}

.summ {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.4rem;
  height: 1.4rem;
  border: 1px solid var(--clr-gray300);
  border-radius: 50%;
  font-weight: 500;
  color: inherit;
  // font-size: .5rem;
  background-color: rgba(#A0A3A6, .3);
}

.filters {
  background-color: var(--clr-gray300);
  color: inherit;
  border: 1px solid var(--clr-gray300);
  font-weight: 500;
}

.active {
  color: inherit;
  background-color: var(--secondary-color);
  border: 1px solid var(--color0);

  :global(.summ) {
    color: var(--color0);
    border: 1px solid var(--color0);
    background-color: var(--secondary-color);
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
  }
}

.apply:hover {
  color: var(--color0);
  background-color: var(--secondary-color);
  border: 1px solid var(--color0);
}

.reset {
  background-color: var(--clr-gray300);
  color: inherit;
  border: none;

  &:hover {
    color: var(--secondary-color);
    background-color: var(--clr-gray400);
  }
}

.container {
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 30;
  top: 3rem;
  right: 0;
  height: 60vh;
  width: 20rem;
  background-color: var(--secondary-color);
  border: 1px solid var(--clr-gray300);
  border-radius: .5rem;

  .header, .headerSecondary {
    border-bottom: 2px solid var(--clr-gray200);
    padding: .7rem 1rem .7rem .4rem;
    display: flex;
    justify-content: space-between;
    align-items: end;

    span:last-child {
      font-size: .9rem;
      text-transform: none;
      color: var(--color0);
      font-weight: 500;
      
      &:hover {
        cursor: pointer;
      }

    }
    
    span {
      text-transform: uppercase;
      font-weight: 500;
      font-size: .85rem;
      letter-spacing: 1px;
    }
  }

  .headerSecondary:hover {
    cursor: pointer;
  }
}

.container:has(.collapsiblesWrapper:focus-within) {
  display: flex;
}


.collapsiblesWrapper {
  overflow: auto;
  scrollbar-color: var(--color0);
  margin-bottom: auto;
  

  &::-webkit-scrollbar {
    background: var(--secondary-color);
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color0);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
}

.show {
  display: flex;
}

.headerSecondary {
  margin-left: 0.5rem;
  span:first-child {
    text-transform: none;
    font-size: .9rem;
    letter-spacing: normal;
    display: flex;
    gap: .5rem;
    align-items: center;


    .headerName {
      color: var(--clr-gray500);
    }

    .summ {
      background-color: var(--color0);
      color: var(--secondary-color);
      font-size: 0.65rem;
    }

    .includeSumm {
      background-color: var(--color0);
      color:  var(--secondary-color);
    }
    .excludeSumm {
      background-color: var(--color2);
      color:  var(--secondary-color);
    }

    .include {
      color: var(--color0);
    }
    .exclude {
      color:  var(--color2);
    }
  }

}

.controls {
  position: sticky;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
  border-radius: 0 0 inherit inherit;
}

.excludeFilterContainer {
  padding: .3rem 1rem 0 1rem;
}

.switchContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: .5rem;
  margin: .3rem 0;

  .switchLabel {
    font-weight: 500;
    font-size: 0.9rem;
  }
}



