.tabs .tab-headers {
  display: flex;
  justify-content: space-around;
  padding: 1.3rem 0;
  text-align: center;
}

.tabs .tab-headers .tab-header {
  width: 100%;
  padding-bottom: 1rem;
}

.tabs .tab-header {
  cursor: pointer;
}

.tabs .tab-header.active {
  border-bottom: 1px solid blue;
}

.tabs .tab-content {
}

.tab-content .option .title {
  font-weight: 600;
  margin-bottom: 0.4rem;
}

.tab-content .option .help {
  font-size: 0.8rem;
  margin-right: 1.5rem;
}

.tab-content .option {
  display: flex;
  justify-content: space-between;
  margin: 1.7rem 0;
}

.tab-content .export-pdf-container {
  display: flex;
  justify-content: center;
  height: 250px;
  align-items: center;
}
