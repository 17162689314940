.storyContentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.controlsPanel {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
}

.storyDescriptionContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 1.5rem;
  padding: 1rem;
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  background-color: var(--secondary-color);
  margin: 0 0 1rem 0;
  width: 100%;

  .saveButton {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 6px;
    padding: 12px 12px;

    cursor: pointer;
    outline: none;
    font-size: 1rem;

    color: var(--clr-gray500);
    background-color: var(--secondary-color);
    border: 1px solid var(--clr-gray350);
    border-radius: 4px;
    transition: color 250ms ease, background-color 250ms ease;


    &:hover {
      background-color: #2563eb;
      color: #fff;
      border: 1px solid #2563eb;
  }
  }

  .storyDescriptionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;
    padding: .5rem 0;
    
  }

  .storyTitle {
    padding: 0;
    margin: 0;
    white-space: wrap;
    word-wrap: break-word;
  }

  .storyContent {
    display: flex;
    color: var(--clr-grey-500);
    line-height: 1.4;
    white-space: wrap;
    word-wrap: break-word;
  } 
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 600px;
    height: 600px;
   }
}

.widgetLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  background-color: var(--secondary-color);
}

.noData {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  border: 2px solid transparent;
  background-color: var(--secondary-color);
  margin-bottom: 1rem;
}