
.root {
  position: relative;
}

.button {
  white-space: nowrap;
  border: 1px solid var(--clr-gray300);
  padding: .5rem 1rem;
  border-radius: 4px;

  &:hover {
    filter: brightness(105%);
  }
  
}

:global(.summ) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.55rem;
  height: 1.55rem;
  border: 1px solid var(--clr-gray300);
  border-radius: 50%;
  font-weight: 500;
  color: inherit;
  font-size: .85rem;
  background-color: rgba(#A0A3A6, .3);
}

.filters {
  background-color: var(--clr-gray300);
  color: inherit;
  border: 1px solid var(--clr-gray300);
  font-weight: 500;
}

.active {
  color: inherit;
  background-color: var(--secondary-color);
  border: 1px solid var(--color0);

  :global(.summ) {
    color: var(--color0);
    border: 1px solid var(--color0);
    background-color: var(--secondary-color);
  }
}

.apply:hover {
  color: var(--color0);
  background-color: var(--secondary-color);
  border: 1px solid var(--color0);
}

.reset {
  background-color: var(--clr-gray300);
  color: inherit;
  border: none;

  &:hover {
    color: var(--secondary-color);
    background-color: var(--clr-gray400);
  }
}

.container {
  display: none;
  flex-direction: column;
  position: absolute;
  z-index: 30;
  top: 3rem;
  right: 0;
  width: 20rem;
  background-color: var(--secondary-color);
  border: 1px solid var(--clr-gray300);
  border-radius: .5rem;

  .header, .headerSecondary {
    border-bottom: 2px solid var(--clr-gray200);
    padding: .7rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: end;

    span:last-child {
      font-size: .9rem;
      text-transform: none;
      color: var(--color0);
      font-weight: 500;
      
      &:hover {
        cursor: pointer;
      }

    }
    
    span {
      text-transform: uppercase;
      font-weight: 500;
      font-size: .85rem;
      letter-spacing: 1px;
    }
  }

  .headerSecondary:hover {
    cursor: pointer;
  }
}


.collapsiblesWrapper {
  overflow: auto;
  scrollbar-color: var(--color0);
  margin-bottom: auto;
  

  &::-webkit-scrollbar {
    background: var(--secondary-color);
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color0);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
}

.show {
  display: flex;
}

.headerSecondary {

  span:first-child {
    text-transform: none;
    font-size: 1rem;
    letter-spacing: normal;
    display: flex;
    gap: .5rem;
    align-items: center;

    :global(.summ) {
      background-color: var(--color0);
      color: var(--secondary-color);
    }
  }
}

.controls {
  position: sticky;
  display: flex;
  padding: 1rem;
  gap: 1rem;
  width: 100%;
  border-radius: 0 0 inherit inherit;
}

.inputsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: .5rem;
  margin: 1rem 0 0 0;

  .inputContainer {
    width: 45%;
    position: relative;
  }

  .output {
    width: 100%;
    padding: .5rem 1rem;
    border-radius: 4px;
    outline: none;
    border:  1px solid var(--clr-gray300);
    font-weight: 500;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.02);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    &#from {
      padding-left: 3.5rem;
    }

    &#to {
      padding-left: 2.3rem;
    }

    &:focus {
      border: 1px solid var(--clr-gray350);
    }

    &:invalid {
      border: 1px solid var(--color2);
    }
  }

  label{
    position: absolute;
    left: .5rem;
    top: .5rem;
    color: var(--clr-gray350);
    font-size: .9rem;
  }
}

