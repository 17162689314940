.root {
  height: 40px;
  display: flex;
  align-items: center;
  overflow-x: hidden;

  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--clr-gray200);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.02);
}

.icon {
  display: inline-block;
  margin-left: 12px;
  color: var(--clr-gray350);

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.input {
  //width: 250px;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border: none;
  outline: none;
  font-size: 1.1rem;
  background-color: transparent;

  &::placeholder {
    color: var(--clr-gray350);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .isHighlighted & {
    background-color: rgba(160, 163, 166, 0.2);
  }
}

@keyframes Drop {
  from {
    transform: translateY(-4px);
  }

  to {
    transform: translateY(0);
  }
}
