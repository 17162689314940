.carousel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
  transition: transform 0.5s ease-in-out;
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.prevButton, .nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  width: 4rem;
  height: 6rem;

  &:hover {
    transition: all 0.5s ease;
    background-color: rgba(0, 0, 0, 0.6);
  }



  & svg {
    width: 40px;
    height: 40px;
  }
}

.prevButton {
  left: 10px;
}

.nextButton {
  right: 10px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  user-select: none;

  &:hover {
    transform: scale(1.1);
    transition: all 0.3s ease;
  }

  & svg {
    width: 40px;
    height: 40px;
  }
}
