.feedbackContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;

  .feedbackIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    background-color: var(--clr-gray200);
    border-radius: 4px;

    svg {
      width: 24px;
      height: 24px;
    }

    &:hover {
      cursor: pointer;
      transform: translateY(-2px);
    }
  }

  .feedbackRelevant {
    background-color: var(--info-light);
    color: var(--color0);
  }

  .feedbackIrrelevant {
    background-color: var(--error-light);
    color: var(--error-primary);
  }

  .feedbackIsDone {
    background-color: var(--success-light);
    color: var(--success-medium);

    &:hover {
      cursor: default;
      transform: none;
    }
  }
}