.breakdownContainerWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid var(--clr-gray300);
  background-color: var(--secondary-color);
  box-shadow: var(--box-shadow);

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid var(--clr-gray300);
    

    .headerTitle {
      text-transform: uppercase;
      color: var(--clr-gray500);
      font-size: .8rem;
      font-weight: 500;
    }

    .headerButton {
      text-transform: uppercase;
      border-radius: 4px;
      border: 1px solid var(--clr-gray400);
      white-space: nowrap;
      color: var(--clr-gray400);
      padding: 6px 10px;
      font-size: .8rem;
      font-weight: 500;

      &:hover {
        color: var(--color0);
        border-color: var(--color0);
        cursor: pointer;
      }
    }
  }

  .body {
    padding: 0;
  }
}


@media print {
  .headerButton {
    display: none !important;}

  .breakdownContainerWrapper {
    break-inside: avoid;
  }
}
