.imageContainer{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: .3rem;
  background-color: var(--clr-gray300);
  // box-shadow: var(--box-shadow);
  // max-width: 150px;
  // min-width: 150px;
  // max-height: 200px;
  position: relative;
  // overflow: hidden;

  img {
    opacity: 0;
    max-width: 100%;
    border-radius: 8px;
    object-fit: fill;

    &:hover {
      cursor: pointer;
    }
  }

  .skeletonCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    // top: 0;

    animation: pulse 2s infinite;

    @keyframes pulse {
      0% {
      opacity: 1;
      }
      50% {
      opacity: 0.5;
      }
      100% {
      opacity: 1;
      }
    }

    svg {
      width: 100px;
      height: 100px;
      color: var(--clr-gray350);
    }
  }

  &:has(.skeletonCard) {
    height: 200px;
    width: 150px;
  }


  &.loaded {
    img {
      opacity: 1;
      transition: opacity 1s ease-in-out;
      object-fit: fill;
      max-width: 250px;
      max-height: 200px;
    }

    .skeletonCard {
      display: none;
      animation: none;
    }
  }
}