.workspaces .name {
  width: 30%;
}

.workspaces-search-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.workspaces-search-input {
  width: 100%;
}
