.sentimentContainer {
  position: relative;
}

.positive, .neutral, .negative {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem .1rem;
  padding-left: .1rem;
  border-radius: 4px;

  svg {
    margin: 0;
  }
}

.positive {
  background-color: var(--success-light);
  
  svg {
    color: var(--success-primary)!important;
  }
}

.negative {
  background-color: var(--error-light);

  svg {
    color: var(--error-primary)!important;
    fill: var(--error-primary)!important;
  }
  
}

.neutral {
  background-color: var(--warning-light);
  svg {
    color: var(--warning-primary)!important;
  }
  
}  

.sentimentChooser {
  position: absolute;
  background-color: var(--secondary-color);
  border: 1px solid var(--clr-gray200);
  border-radius: 4px;
  padding: 1rem 1rem 1rem 0.5rem;
  box-shadow: var(--box-shadow);
  top: 0;
  left: 2.1rem;
  z-index: 10;


  ul {
    display: flex;
    gap: .5rem;
    flex-direction: column;
    li {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: .3rem;


      input[type='radio'] {
        display: inline;
        &:hover {
          cursor: pointer;
        }
      }

      label {
        display: inline-block;
      }
    }
  }
}