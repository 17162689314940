.sortingControls {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  gap: 1.5rem;

  .search {
    flex: 1 0 0;
  }
}

.threatEditModal {
  :global(.modal-dialog) {
    min-width: 1200px;
  }

    .formElement {
      label {
        display: block;
        margin-bottom: .5rem;
      }
      margin-bottom: 1.5rem;

     input[type='text'],
     textarea {
      width: 100%;
      font-size: 1.3rem;
      padding: 0.5rem;
      border: 1px solid var(--clr-gray300);

      &:required:invalid {
        border-color: red;
      }
     }

    }

    .cautionMessage {
      display: flex;
      flex-direction: row;
      gap: .5rem;
      font-size: .9rem;
      margin: 2rem 0;
      color: var(--warning-medium);

      .infoIcon {
        // padding-top: .1rem;
      }

    }

    
}

.typeInformationWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  background-color: var(--clr-gray150);
  padding: 1.5rem 1rem;
  border-radius: 6px;

  .infoIcon {
    color: var(--clr-gray400);
    display: flex;
    align-items: center;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .typeInformation {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    color: var(--clr-gray400);

    .typeInformationTitle {
      text-transform: uppercase;
      font-size: .9rem;
      font-weight: 500;
      color: var(--clr-gray500);
    }
  }

}
