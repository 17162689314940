
.root {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    padding: 1rem;
    color: var(--color0);
    background-color: var(--color0l);
    margin-bottom: 1rem;
}

.buttonText {
    padding: 0 1rem !important;
}