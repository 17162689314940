.chartWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--secondary-color);

  #chart_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px
  }

  .noDataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    font-size: 1.5rem;
    color: var(--clr-gray400);
    background-color: transparent;
    position: absolute;
    top: 0;
  
  }
}

.widdgetLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  background-color: var(--secondary-color);
}