.tooltip-container {
  position: relative;
  display:inline-flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  position: absolute;
  z-index: 3;
  padding: .5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  opacity: 0;
  pointer-events: none;
  white-space: normal;
  transition: opacity 0.2s ease-in-out;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  min-width: max-content;

  &::after {
    content: '';
    position: absolute;
    border: 6px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.9);
  }

  &.visible {
    opacity: 1;
  }
}


.top {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
}

.bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.5rem;
}

.left {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  margin-right: 0.5rem;
}

.right {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: 0.5rem;
}

.tooltip.top::after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top-color: rgba(0, 0, 0, 0.9);
}

.tooltip.bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
  border-top-color: rgba(0, 0, 0, 0.9);
}

.tooltip.left::after {
  top: 50%;
  left: 100%;
  transform: translateY(-50%) rotate(270deg);
  border-top-color: rgba(0, 0, 0, 0.9);
}

.tooltip.right::after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%) rotate(90deg);
  border-top-color: rgba(0, 0, 0, 0.9);
}

:global(#nav-toggle:not(:checked) + .sidebar .sidebar-menu a) {
  .tooltip {
    display: none;
  }
}

