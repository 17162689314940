.user_stats .name {
  width: 40%;
}

.user_stats .statistic {
  width: 20%;
}

.user-stats-filter-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
