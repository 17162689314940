.userWrapper {
  display: flex;
  align-items: center;
  cursor: default;

  & h4 {
    color: var(--main-color);
    margin-top: -3px;
  }
}

.maintenanceMessage {
  padding: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--clr-gray200);
  border-radius: 4px;
  border: 1px solid var(--clr-gray300);
  margin-bottom: 3rem;

  .maintenanceMessageClose {
    width: 100%;
    text-align: end;
    :hover {
      color: var(--color0);
      transform: scale(1.1);
      cursor: pointer;
    }
  }
}

.userAvatar {
  border-radius: 50%;
  margin-right: 1rem;
  width: 48px;
  height: 48px;
  text-transform: uppercase;
  color: var(--main-color);
  background-color: var(--secondary-color);
  display: flex;
  justify-content: center;

  & img {
    border-radius: 50%;
    margin-right: 1rem;
    max-width: 48px;
  }

  & span {
    justify-content: center;
    align-self: center;
    font-size: 1.2rem;
    font-weight: 700;
  }
}

.sudoWrapper {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  margin-top: 0.5rem;
  background: var(--color0l);
  color: var(--color0);
  border-radius: 7px;
  border: 1px solid #e7e8e9;
}

.itemText {
  margin-left: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.threatCounter {
  display: inline-block;
  // position: absolute;
  margin: 0 .7rem;
  // top: 0;
  // width: 1rem;
  // height: 1rem;
  border-radius: 12px;
  background-color: var(--color0);
  color: var(--secondary-color);
  font-size: .8rem;
  font-weight: 500;
  text-align: center;
  padding: 4px 8px;
}

.menuLabel {
  cursor: pointer;
}

.userSettings {
  width: 100%;
  padding: 0 1rem;

  @media (max-width: 1200px) {
    padding: 0;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  :global(#nav-toggle:checked + .sidebar) & {
    padding: 0;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  @media (max-width: 768px) {
    padding: 0 1rem;
    justify-content: flex-start;
  }
}

.logoutBtn {
  display: flex;
  gap: 0;
  padding: 0 0 1rem 1rem !important;

  &:hover {
    color: var(--active);
    transition: color 200ms ease;
  }

  @media (max-width: 1200px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  :global(#nav-toggle:checked + .sidebar) & {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  @media (max-width: 768px) {
    padding: 7px 14px !important;
  }
}
