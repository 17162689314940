.root {
  width: 100%;
  margin-top: 1rem;

  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
  justify-content: center;
}

.page,
.dots {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page a,
.dots a,
.page span {
  align-self: center;
}

.arrow {
  background-color: #ffffff;
  border: 1px solid #e7e8e9;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  width: 32px;
  height: 32px;
}

.dots {
  background-color: rgba(160, 163, 166, 0.2);
  color: #a0a3a6;
  width: 32px;
  height: 32px;
  cursor: default;
}

.page {
  background-color: transparent;
  color: #a0a3a6;
  /*width: 24px;*/
  /*height: 12px;*/

  width: 32px;
  height: 32px;

  &:hover {
    background-color: var(--clr-gray200);
  }

  &.active {
    /*background: var(--clr-gray300);*/
    background-color: #2563eb;
    color: #fff;
    cursor: default;
    width: 32px;
    height: 32px;
  }
}

.link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invert {
  transform: scale(-1);
}
