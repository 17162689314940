.sortable {
  cursor: pointer;
  user-select: none;
}

.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content min-content;
  grid-gap: 4px;
  align-items: center;
}

.icon {
  vertical-align: middle;
  opacity: 0;
  transition: opacity 300ms ease;

  &.visible {
    opacity: 1;
  }

  &.reversed {
    transform: rotate(180deg);
  }
}
