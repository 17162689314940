
.d3-tip {
  font-size: 13px;

  z-index: 100;
  padding: 1rem;
  position: absolute;

  color: #A0A3A6;

  background: #2C2C2E;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 6px;
  opacity: 1;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  position: absolute;
  pointer-events: none;
}

/* Northward tooltips */
.d3-tip.n:after {
  content: "\25BC";
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
  text-align: center;
}

/* Eastward tooltips */
.d3-tip.e:after {
  content: "\25C0";
  margin: -4px 0 0 0;
  top: 50%;
  left: -8px;
}

/* Southward tooltips */
.d3-tip.s:after {
  content: "\25B2";
  margin: 0 0 1px 0;
  top: -8px;
  left: 0;
  text-align: center;
}

/* Westward tooltips */
.d3-tip.w:after {
  content: "\25B6";
  margin: -4px 0 0 -1px;
  top: 50%;
  left: 100%;
}

.expand-icon:hover {
  cursor: pointer;
  // stroke: var(--color0);
  stroke: #2563EB;
}
.expand-icon:hover {
  cursor: pointer;
  stroke: var(--color0);
  // stroke: #2563EB;
}

.node.children {
  cursor: pointer;
}

.bar-chart {
  overflow: visible;
}

.bar-chart .grid line {
  stroke: #e7e8e9;

  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.bar-chart .tick {
  color: #a0a3a6;
}

.bar-chart .grid path {
  stroke-width: 0;
}

