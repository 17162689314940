.root {
  label,
  .label {
    display: block;
    margin-bottom: 8px;
  }

  .errorMessage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    border-radius: 4px;
    width: 100%;
    padding: 1rem;
    color: var(--color2);
    background-color: rgba(#ee6c74, .4);

    span {
      display: flex;
      align-items: center;
      color: var(--color2);

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}

.example {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.subLabel {
  font-size: 13px;
  color: #767b91;
}

.submit {
  display: inline-block;
}

.errorDescription {
  margin-left: 8px;
  color: #ee6c74;
}

.help {
  padding-left: 0.3rem;
  svg {
    width: 16px;
    vertical-align: middle;
  }
}

.narrativeTypeContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 2px solid var(--clr-gray200);
  border-radius: 8px;
  background-color: var(--secondary-color);
  margin-bottom: 3rem;
  padding: 1rem;
  box-shadow: var( --box-shadow);


  .typesWrapper {
    display: flex;
    flex-direction: row;
    gap: .5rem;

    .narrativeType {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      border: 2px solid var(--clr-gray200);
      border-radius: 6px;
      background-color: var(--secondary-color);
      color: inherit;
      padding: 1.5rem 1rem;
      font-weight: 600;
      min-width: 200px;
      box-shadow: var( --box-shadow);
      align-items: center;

      &:hover {
        cursor: pointer;
        border-color: var(--color0);
        color: var(--color0);
      }

      &.active {
        border-color: var(--color0);
        color: var(--color0);
      }
    }
  }

  .typeInformationWrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    background-color: var(--clr-gray150);
    padding: 1.5rem 1rem;
    border-radius: 6px;

    .infoIcon {
      color: var(--clr-gray400);
      display: flex;
      align-items: center;
      svg {
        width: 18px;
        height: 18px;
      }
    }

    .typeInformation {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      color: var(--clr-gray400);

      .typeInformationTitle {
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 500;
        color: var(--clr-gray500);
      }
    }

  }
}


.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .label {
    span {
      margin-left: .3rem;
      color: var(--clr-gray400);
      font-size: 13px;
    }
  }
}

label.label {
  font-size: 1rem;
  margin-bottom: .5rem;
  position: relative;


  .labelIcon {
    display: inline-block;
    align-items: center;
    position: relative;
    top: 5px;
    margin-right: 5px;
    
    svg {
      color: var(--clr-gray500);
      width: 22px;
      height: 22px;
    }
  }

  .subLabel {
    font-size: 13px;
    font-weight: 400;
    color: #767b91;
    margin-left: 2px;
  }
}

