.chartTreeModal {

  :global(.modal-dialog) {
    min-width: 1120px;

    :global(.tabs-container) {
      overflow-x: auto;
      overflow-y: hidden;
    
      :global(.tabs-header-wrapper) {
        position: sticky;
        left: 0;
      }
    
    }

    :global(.modal-footer) {
      padding: 0 0 1.5rem 0; 
    }
  }

  .tableWrapper {
    width: auto;
  }
  
}

.modalControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.modalSelects {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  gap: 1rem;

  .chartSelect {
    width: 155px;
  }

  :global(.chartSelect__menu) {
    z-index: 5;
  }
}

.imagesContainer {
  margin-left: .8rem;
}

.modalControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;

  .modalActions {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    justify-content: end;
    border-top: 1px solid var(--clr-gray300);
    padding-top: 2rem;
    padding: 1.5rem 1.5rem 0 1.5rem;

    button {
      & > svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.modalSearchPanel {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: inline-flex;

}
