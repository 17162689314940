.circlechart {
  position: relative;
}

.circlechart .button.reset {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #2563eb;
  border-radius: 8px;
}

@media print {
  .circlechart .button.reset {
    display: none;
  }
}

.popup-position {
  width: 1px;
  height: 1px;
  overflow: visible;
  position: absolute;
  top: 0;
  left: 0;
}
.popup {
  border-radius: 8px;
  padding: 20px;
  border: 1px solid var(--stroke);
  /* if you want to change width remember to change it in the js file in const leftOffset */
  width: 30em;
  color: #171725;
  position: absolute;
  /* if you want to change max-height remember to change it in the js file */
  max-height: 520px;
  /* top: 0;
    right: 0;; */
  /* top:0;
    left:0; */
  bottom: 10px;
  right: 10px;
  background: #fff;
  /* margin-left: -100%; */
}
.popup__more {
  text-decoration: underline;
  cursor: pointer;
}
.popup__close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.circlechart .popup h3 {
  border: 1px solid var(--stroke);
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 16px;
  display: inline-block;
  color: var(--stroke);
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 5px;
}
.popup path {
  stroke: var(--stroke);
}
.popup__title {
  margin-bottom: 1em;
  overflow: auto;
  max-height: 20em;
}
.popup__sources {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 1em;
}
.popup__sources a {
  text-decoration: underline;
  color: #171725;
}
.popup__resume {
  display: flex;
  justify-content: space-between;
  margin: 2em 0;
}
.popup__data {
  display: flex;
  align-items: center;
}
.popup__icon {
  width: 36px;
  height: 36px;
  border-radius: 9px;
  background: var(--background);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.popup__value {
  font-size: 1rem;
  font-weight: 500;
}
.percentage {
  display: flex;
  font-size: 20px;
  align-items: baseline;
}
.percentage__range {
  border-radius: 4px;
  background: var(--background);
  margin-left: 2em;
  height: 12px;
  flex: 1;
}
.percentage__range i {
  background: var(--stroke);
  display: block;
  height: 12px;
  border-radius: 4px;
}

.bubbleChartTooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  color: white;
  font-size: 14px;
  padding: 4px;
  margin-left: 15px;
  transform: translateY(-50%);
}

