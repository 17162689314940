.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: .5rem;

  &:has(.small) {
    gap: 0rem;
    flex-direction: column;
    width: fit-content;
  }
}

.moreImages {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 210px;
  background-color: var(--clr-gray300);
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--clr-primary);
  box-shadow: var(--box-shadow);
  &:hover {
    .moreImagesText {
      transform:translateY(-1px);
      transition: all .3s ease-out;
      
    }
  }

  &.small {
    width: 100%;
    height: 20px;
    font-size: 1rem;
    padding: .8rem .8rem 1rem .8rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    margin-top: -.2rem;
    text-decoration: underline;
  }
}
