.buttonText {
  white-space: nowrap;
}

.sortable {
  cursor: pointer;
  user-select: none;
}

.wrapper {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content min-content;
  grid-gap: 4px;
  align-items: center;
}

.tableWrapper {
  // overflow-x: auto;
  // overflow-y: auto;
  width: auto;
  // max-height: 60vh;
  // border-bottom: 1px solid var(--clr-gray300);
  // border-top: 1px solid var(--clr-gray300);
}

.actorGroups {
  background-color: var(--secondary-color);
  .controls {
    display: flex;
    flex-direction: row;
    gap: .5rem;

    :global(.new-button) {
      padding: 7px 14px;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid var(--active);
      }
    }
  }
  .gropNameWrapper {
    display: flex;
    flex-direction: row;
    gap: .5rem;
  }

  .groupNameContainer {
    display: flex;
    flex-direction: column;
    gap: .4rem;
    line-height: 1;
    font-size: 1rem;
    font-weight: 500;

    & > span  >  a {
      font-size: 1rem;
      color: var(--text-headline);

      &:hover {
        cursor: pointer;
        color: var(--clr-gray400);
      }
    }


    .groupInfo {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: .3rem;
      font-size: .9rem;

      & >  a {
        font-size: .9rem;
        color: var(--main-color);
      }

      .userName {
        color: var(--clr-gray500);
        font-weight: 500;
      }


      span {
        font-weight: 400;
        color: var(--clr-gray500);
        white-space: nowrap;
      }

      .active {
        color: var(--success-medium);
      }

      .inactive {
        color: var(--error-primary);
      }
    }
  }

  tr, td {
    border: 1px solid var(--clr-gray300);
  }

  td  {
    font-size: .9rem;
    vertical-align: top;
    width: fit-content;
    // white-space: nowrap; doesn't work in safari properly with table

    i {
      font-size: 1.2rem;
    }
  }

  td:first-of-type {
    // position: sticky;
    left: -1px;
    // background-color: var(--secondary-color);
    // border-top: none;
    white-space: wrap;
    min-width: 500px;
    // max-width: 600px;

    // &::after {
    //   content: '';
    //   width: 2px;
    //   height: 100%;
    //   position: absolute;
    //   right: 0;
    //   top: 0;
    //   background-color: var(--clr-gray300);
    //   box-shadow: 3px 0px 2px -1px rgba(0,0,0,0.18);

    // }

  }

  // td:last-of-type {
  //   position: sticky;
  //   right: -1px;
  //   background-color: var(--secondary-color);
  //   // border-top: none;
  //   white-space: wrap;
  //   min-width: 50px;
  //   max-width: 150px;

  //   &::before {
  //     content: '';
  //     width: 2px;
  //     height: 100%;
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     background-color: var(--clr-gray300);
  //     box-shadow: 3px 0px 2px -1px rgba(0,0,0,0.18);

  //   }

  // }


  td:last-child {
    width: 1%;
  }
}

.searchWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.searchInput {
  width: 100%;
}
