.bullet {
  display: inline-flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  color: var(--color0);
  margin-right: .2rem;
  height: 100%;

  .bulletIcon {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color0);
  }
}

.storyCardModal {
  :global(.modal-dialog) {
    min-width: 900px;
  }

  .modalForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}

.adminCheckbox {
  display: none;
  height: 15px;
  width: 15px;
}






.storyCardContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  justify-content: center;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  border: 2px solid transparent;
  background-color: var(--secondary-color);

  &:hover {
    border-color: var(--color0);
    cursor: pointer;

    .storyCardHeader {
      .adminCheckbox {
        display: flex;
      }
      .controls {
        display: flex;
        gap: .5rem;
      }
    }
  }
}

.adminCheckbox.adminCheckboxChecked {
  display: flex;
}


.storyCardContainer.active {
  border-color: var(--color0);
}

.storyCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .controls {
    display: none;
    gap: .5rem;
  }
}

.storyCardDate {
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.4;
  color: var(--clr-gray500);
  display: flex;
  align-items: center;
  justify-content: start;
  gap: .4rem;
  text-transform: capitalize;
}

.storyCardHeader {
  font-size: .9rem;
  font-weight: 500;
  line-height: 1.4;
  color: var(--main-color);
}
.storyCardHeader.italic {
  font-style: italic;
}

.storyCardInfo {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.storyCardRatingContainer, .storyCardSourcesContainer, .storyCardOpinionBalanceContainer, .storyCardSentimentContainer, .opinionCardMessagesContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: .4rem;

  .storyCardHint {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .2rem;
    line-height: 1.1;

    .storyCardHintText {
      color: var(--clr-gray350);
      font-size: .8rem;
    }

    .storyCardHintIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        width: 15px;
        height: 15px;
        color: var(--clr-gray300);
      
      }
    }
  }
}

.storyCardRatingContainer, .opinionCardMessagesContainer {
  .storyCardRating, .opinionCardMessages  {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    font-size: 1.2rem;
    font-weight: 600;

    .opinionCardMessagesValue {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
    }

    .storyCardRatingValue, .storyCardRatingIcon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  } 
}

.storyCardOpinionBalance {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .5rem;

  .storyCardOpinionBalancePositive, .storyCardOpinionBalanceNegative, .storyCardOpinionBalanceNeutral {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: .9rem;
    font-weight: 500;
    line-height: 1.4;
    padding: .3rem .4rem;
  }

  .storyCardOpinionBalancePositive {
    color: var(--success-medium);
    background-color: var(--success-light);
    border-radius: 4px;
  }

  .storyCardOpinionBalanceNegative {
    color: var(--error-primary);
    background-color: var(--error-light);
    border-radius: 4px;
  }

  .storyCardOpinionBalanceNeutral {
    color: var(--warning-primary-dark);
    background-color: var(--warning-light);
    border-radius: 4px;
  }
}

.storyCardSentimentContainer {
  .storyCardSentiment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    font-size: .9rem;
    font-weight: 500;
    padding: .3rem .4rem;
    border-radius: 4px;

  }

  .positive {
    color: var(--success-medium);
    background-color: var(--success-light);
  }

  .negative {
    color: var(--error-primary);
    background-color: var(--error-light);
  }

  .neutral {
    color: var(--warning-primary-dark);
    background-color: var(--warning-light);
  }
}

.storyCardButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: .2rem .3rem;
  border-radius: 4px;
  font-size: .8rem;
  color: var(--clr-gray500);
  background-color: var(--secondary-color);
  transition: background-color 250ms ease, color 250ms ease;
  border: 1px solid var(--clr-gray350);

  svg {
    width: 17px;
    height: 17px;
  }

  &:hover {
    background-color: var(--color0);
    color: var(--secondary-color);
    border: 1px solid var(--color0);
  }
}

.skeletonCardContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: var(--color0l);
  border-radius: 8px;
  width: 100%;
  min-height: 200px;
  gap: 1.5rem;
  position: relative;
}

// .active {
//   animation: pulse 2s ease-in-out infinite;
// }

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.skeletonCardContainer {
  background: linear-gradient(
    90deg,
    #e9effd 25%,
    #f4f3f9 50%,
    #e9effd 75%
  );
  background-size: 200% 100%;
  animation: shimmer 2s infinite;
}

// @keyframes pulse {
//   0% {
//     background-color: var(--color0l);
//   }
//   50% {
//     background-color: var(--info-medium-light);
//   }
//   100% {
//     background-color: var(--color0l);
//   }
// }

.skeletonStoryCardHeader,
.skeletonStoryCardDate,
.skeletonStoryCardRatingIcon,
.skeletonStoryCardRatingValue,
.skeletonAvatarGroup,
.skeletonStoryCardOpinionBalancePositive,
.skeletonStoryCardOpinionBalanceNegative {
  background-color: var(--clr-gray300);
  border-radius: 4px;
}

.skeletonStoryCardInfo {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.skeletonStoryCardDate {
  width: 60%;
  height: 16px;
  margin-bottom: 10px;
}

.skeletonStoryCardHeader {
  width: 90%;
  height: 25px;
  margin-bottom: 10px;
}

.skeletonStoryCardRatingContainer,
.skeletonStoryCardOpinionBalanceContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: row;
}

.skeletonStoryCardOpinionBalance {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
}

.skeletonStoryCardRatingIcon {
  width: 40px;
  height: 25px;
}

.skeletonStoryCardRatingValue {
  width: 40px;
  height: 25px;
}

.skeletonAvatarGroup {
  width: 100px;
  height: 25px;
  margin-bottom: 10px;
}

.skeletonStoryCardOpinionBalancePositive,
.skeletonStoryCardOpinionBalanceNegative {
  width: 40px;
  height: 25px;
}


