:global(.card-body) {
  width: auto !important;
}

:global(.tabs-container) {

  :global(.tabs-header-wrapper) {
    position: sticky;
    left: 0;
  }

  .tableWrapper {
    overflow-x: auto;
    overflow-y: auto;
    width: auto;
    max-height: 60vh;
    border-bottom: 1px solid var(--clr-gray300);
    // border-top: 1px solid var(--clr-gray300);
  }

}



.threats {
  background-color: var(--secondary-color);
  

  .threatNameWrapper {
    display: flex;
    flex-direction: row;
    gap: .5rem;

    .threatname {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-content: center;
      gap: .1rem;
      
      & > a {
        font-size: 1rem;
        color: var(--text-headline);

        &:hover {
          cursor: pointer;
          color: var(--clr-gray400);
        }
      }
    }

    .new {
      display: inline-flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      color: var(--color0);
      margin-right: .2rem;
      height: 100%;

      .newBullet {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--color0);
      }
    }
  }

  .threatNameContainer {
    display: flex;
    flex-direction: column;
    gap: .4rem;
    line-height: 1;
    font-size: 1rem;
    font-weight: 500;



    .threatInfo {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      gap: .3rem;
      font-size: .9rem;

      & >  a {
        font-size: .9rem;
        color: var(--text-headline);
      }

      .userName {
        font-weight: 500;
      }
  

      span {
        font-weight: 400;
        color: var(--clr-gray500);
        white-space: nowrap;
      }

      .active {
        color: var(--success-medium);
      }

      .paused {
        color: var(--warning-primary);
      }

      .archived {
        color: var(--error-primary);
      }
    }
  }

  tr, td {
    border: 1px solid var(--clr-gray300);
  }
  
  td  {
    font-size: .9rem;
    vertical-align: top;
    width: fit-content;
    white-space: nowrap;
  
    i {
      font-size: 1.2rem;
    }
  }

  td:first-of-type {
    position: sticky;
    left: -1px;
    background-color: var(--secondary-color);
    // border-top: none;
    white-space: wrap;
    min-width: 500px;
    // max-width: 600px;
  
    &::after {
      content: '';
      width: 2px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background-color: var(--clr-gray300);
      box-shadow: 3px 0px 2px -1px rgba(0,0,0,0.18);
  
    }
    
  }

  td:last-of-type {
    position: sticky;
    right: -1px;
    background-color: var(--secondary-color);
    // border-top: none;
    white-space: wrap;
    min-width: 50px;
    max-width: 150px;
  
    &::before {
      content: '';
      width: 2px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--clr-gray300);
      box-shadow: 3px 0px 2px -1px rgba(0,0,0,0.18);
  
    }
    
  }


  td:last-child {
    width: 1%;
  }
  
  .new {
    font-weight: 600;
  
    a {
      color: var(--text-headline);
      &:hover {
        color: var(--clr-gray400);
      }
    }
  }

  .badge {
    border: 1px solid var(--clr-gray350);
    border-radius: 3px;
    margin-right: .5rem;
    line-height: 1.1rem;
    padding: 3px 5px;
    font-size: .8rem;
    white-space: nowrap;
    font-weight: 500;
    margin-bottom: .2rem;
    color: var(--main-color);
  }

  .mark {
    border-radius: 3px;
    padding: 4px 6px;
    font-size: .8rem;
    font-weight: 500;
    white-space: nowrap;
    margin-right: .5rem;
    line-height: 1.1rem;
    margin-bottom: .2rem;
    background-color: var(--clr-gray200);
  }

  .tagsContainer {
    display: inline-flex;
    max-width: 270px;
    flex-direction: row;
    flex-wrap: wrap;

    
    
    .fake {
      color: var(--warning-medium);
      border-color: var(--warning-medium-border);
    }
    
    .leak {
      color: var(--info-primary);
      border-color: var(--info-medium-border);
    }
    
    .online {
      color: var(--success-medium);
      border-color: var(--success-medium-border);
    }
    
    .offline {
      color: var(--purple-primary);
      border-color: var(--purple-medium);
    }
    
    .malign {
      color: var(--pink-primary);
      border-color: var(--pink-medium-border);
    }

    .debunk {
      color: var(--cyan-medium);
      border-color: var(--cyan-medium-border);
    }

    .politics {
      background-color: var(--lilac-light);
    }

    .finance {
      background-color: var(--blue-light);
    }

    .publicCompanies {
      background-color: var(--green-light);
    }

    .elections {
      background-color: var(--violet-light);
    }

    .defence {
      background-color: var(--yellow-light);
    }

    .cyber {
      background-color: var(--orange-light);
    }


  }

  .statusWrapper {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    font-weight: 500;
    white-space: nowrap;

    & .accepted {
      color: var(--success-medium) !important;
    }
  
    & .pending {
      color: var( --warning-primary-dark) !important;
    }
  
    & > .declined {
      color: var(--error-primary) !important;
    }

  }

  .status {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }

  

  .controls {
    display: flex;
    flex-direction: row;
    gap: .5rem;
  }

  a.edit {
    padding: 5px 7px;
  }

  .save {
    padding: 5px 7px;
  }

  .delete, .edit {
    border-radius: 5px;
    padding: 3px 5px;
  }

  .delete {
    background-color: var(--error-primary);

    &:hover {
      svg {
        color: var(--error-primary);
      }
      
      background-color: transparent;
      border-color: var(--error-primary);
    }
  }
  
}

.noData {
  width: 100%;
  margin: 5rem auto;
  text-align: center;
  font-weight: 500;
}

@media only screen and (min-width: 2100px) {
  .threats {
    .threatNameContainer {
      font-size: 1.3rem;
      .threatInfo {
        font-size: 1.1rem;
  
        & >  a {
          font-size: 1.2rem;
        }
      }
  
    }
    
    tr, td {
      font-size: 1.2rem;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .threats {
    td:first-of-type {
      min-width: 400px;
    }
  }
}

@media only screen and (max-width: 1220px) {
  .threats {
    td:first-of-type {
      min-width: 350px;
    }
  }
}