.root {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
}

.input {
  position: absolute;
  left: 0;
  visibility: hidden;

  &:focus + .label::before {
    outline: 0;
  }

  &:checked ~ .label::after {
    transform: rotate(-45deg) scale(1);
  }
}

.label {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 2px solid var(--clr-gray500);
    transition: border-radius 300ms ease;
  }

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 6px;
    border-bottom: 2px solid var(--clr-gray500);
    border-left: 2px solid var(--clr-gray500);
    transform: rotate(-45deg) scale(0);
    transition: transform 300ms ease;
    will-change: transform;
    position: absolute;
    top: 7px;
    left: 5px;
  }

  .isLoading &::before {
    border-radius: 50%;
  }
  .isLoading &::after {
    opacity: 0.8;
  }
}

.root:has(.input:disabled) {
  .label {
    cursor: not-allowed;
    &::before {
      border: 2px solid var(--clr-gray350);
    }
    &::after {
      border-bottom: 2px solid var(--clr-gray350);
      border-left: 2px solid var(--clr-gray350);
    }
  }
}


.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
