.root .tableRow {
  padding: 0 16px !important;
  height: 40px;
  vertical-align: top;
  display: table-cell;
}

.root thead .tableRow {
  padding: 16px 16px !important;
  height: 60px;
}

.showOnlySelected {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.searchWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.searchInput {
  width: 100%;
}

.descriptionCard {
  background: var(--secondary-color);
  color: var(--main-color);
  margin-bottom: 3rem;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  border: 1px solid var(--clr-gray200);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 5px 4px -4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  & > ul li{
    list-style-type: disc!important;
    list-style-position: inside;
  }
  
  & > ol li{
    list-style-type: decimal!important;
    list-style-position: inside;
  }
  
  p:not(:first-child) {
    margin-top: .7rem;
  }
}

.root {
  :global(.report-section) {
    margin-top: 0;
    & > h3 {
      margin-top: 0;
    }
    .descriptionCard {
      margin-bottom: 2rem;
    }
  }
}
