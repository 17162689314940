.widgetHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  & > h3 {
    margin-top: 1rem!important;
  }
  
  .widgetControls {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: .5rem;
    
  }
}

.mergeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: .5rem .7rem;
  border-radius: 4px;
  font-size: .9rem;
  color: var(--clr-gray500);
  background-color: var(--secondary-color);
  transition: background-color 250ms ease, color 250ms ease;
  border: 1px solid var(--clr-gray350);

  // svg {
  //   width: 22px;
  //   height: 22px;
  // }

  &:hover {
    background-color: var(--color0);
    color: var(--secondary-color);
    border: 1px solid var(--color0);
  }
}

.errorStoriesMerge {
  color: var(--error-primary);
}

.mergeEventsModal {
  :global(.modal-dialog) {
    min-width: 900px;
  }

  .modalForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}

.widgetLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  background-color: var(--secondary-color);
}

.noData {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  border: 2px solid transparent;
  background-color: var(--secondary-color);
  margin-bottom: 1rem;
}
