.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  animation-name: appear;
  animation-duration: 300ms;
}

.modal-dialog {
  max-width: 1000px;
  min-width: 550px;
  // width: 100%;
  // max-width: 550px;
  background: white;
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: slide-in;
  animation-duration: 0.5s;
  border-radius: 10px;
}

.modal-header,
.modal-footer {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
}

.modal-header {
  border-bottom: 1px solid #dbdbdb;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #dbdbdb;
  justify-content: space-between;

  button.secondary {
    background-color: var(--clr-gray300);
    color: inherit;
    border: 1px solid var(--clr-gray300);

    &:hover {
      background-color: var(--clr-gray400);
      color: var(--secondary-color);
    }
  }
}

.modal-close {
  cursor: pointer;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
  translate: all .2s;

  &:hover {
    transform: scale(1.2) translateY(-1px);
    color: var(--color0);
  }
}

.modal-body {
  overflow: auto;
  scrollbar-color: var(--color0);

  &::-webkit-scrollbar {
    background: var(--secondary-color);
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color0);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }
}

.modal-content {
  padding: 1rem 1.5rem;
  position: relative;

  &:has(.modal-search) {
    padding: 0;
  }
 
  .modal-search {
    position: sticky;
    top: 0;
    background-color: var(--secondary-color);
    border-bottom: 2px solid var(--clr-gray200);
    padding: .5rem;
  }

  input, textarea {
    border-radius: 4px;
    // margin-top: .5rem;
    font-size: 1rem;
    position: sticky;
    top: 0;
  }
}

.modal-content:has(.form-element) {
  label {
    font-size: .9rem;
    font-weight: 600;
    display: block;
    margin-bottom: .5rem;
  }
}



@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    transform: translateY(-150px);
  }
  to {
    transform: translateY(0);
  }
}

.modal-footer button {
  padding: 0.5rem 1rem;
}
