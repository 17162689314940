.root {
  label,
  .label {
    display: block;
    margin-bottom: 8px;
  }

  .errorMessage {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    border-radius: 4px;
    width: 100%;
    padding: 1rem;
    color: var(--color2);
    background-color: rgba(#ee6c74, 0.4);

    span {
      display: flex;
      align-items: center;
      color: var(--color2);

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
      }
    }
  }
}
