.sliderContainer {
  width: 45%;
}



.range-slider {
  margin: 60px 0 0 0;
}


// Range Slider

$range-handle-color: var(--color0) !default;
$range-handle-color-hover: var(--info-primary) !default;
$range-handle-size: 20px !default;

$range-track-color: var(--clr-gray300) !default;
$range-track-height: 5px !default;

$range-label-color: var(--clr-gray200) !default;
$range-label-width: 60px !default;

.rangeSlider {
  width: 100%;
}

.input {
  -webkit-appearance: none;
  width: calc(100% - (#{$range-label-width + 13px}));
  height: $range-track-height;
  border-radius: $range-track-height;
  background: $range-track-color;
  outline: none;
  padding: 0;
  margin: 0;

  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-webkit-slider-thumb {
    background: $range-handle-color-hover;
  }

  &::-moz-range-thumb {
    width: $range-handle-size;
    height: $range-handle-size;
    border: 0;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background .15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-moz-range-thumb {
    background: $range-handle-color-hover;
  }
  
  // Focus state
  &:focus {
    
    &::-webkit-slider-thumb {
      box-shadow: 0 0 0 2px var(--secondary-color),
                  0 0 0 4px var(--info-primary);
    }
  }

  &:disabled {
    &::-webkit-slider-thumb {
      background: $range-track-color;
    }

    &::-moz-range-thumb {
      background: $range-track-color;
    }
  }
}


// Range Label
.value {
  display: inline-block;
  position: relative;
  width: $range-label-width;
  color: var(--main-color);
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: $range-label-color;
  padding: 5px 10px;
  margin-left: 12px;

}


// Firefox Overrides
::-moz-range-track {
    background: $range-track-color;
    border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer { 
  border: 0; 
}

.description {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: .8rem;
  margin-top: .3rem;
  color: var(--clr-gray400);
}

@media only screen and (max-width: 1200px) {
  .sliderContainer {
    width: 70%;
  }
}
