.root {
  display: flex;
  flex-direction: column;
}

.reportHeader {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 1rem;
  background-color: var(--secondary-color);
  padding: 1.5rem;
  border-radius: 6px;
  box-shadow: var(--box-shadow);
  margin: 1rem 0;

  & > h2 {
    font-weight: 400;
  }
}

.reportContent {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 1rem 0;
  position: relative;


  .reportFeed, .reportStory {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .reportStory {
    width: 100%;
  }
}

.parametersPanel {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: start;
  margin-bottom: 2.5rem;
  // scroll-margin-top: 5rem;

  .parameterContainer, .parametersContainerEditable {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    justify-content: center;
    padding: .4rem .6rem;
    background-color: var(--clr-gray150);
    border-radius: 4px;
    border: 1px solid var(--clr-gray300);


    .parameterTitle {
      color: var(--clr-gray500);
    }
    .parameterValue {
      color: var(--main-color);
      font-weight: 500;
    }
  }

  .parametersContainerEditable {
    cursor: pointer;
    transition: background-color 250ms ease;

    &:hover {
      background-color: var(--clr-gray300);
    }
  }
}

.updateDateTimeCalendar {
  display: flex;
  left: -10rem;
}
