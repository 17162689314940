.container {
    display: flex;
    flex-direction: column;
    font-family: Inter, 'Lexend Deca', sans-serif;
}

.button {
    white-space: nowrap;
    border: 1px solid var(--clr-gray300);
    padding: .5rem .7rem;
    border-radius: 8px;
}

.date {
    display: flex;
    justify-content: space-between;
    padding: .5rem .7rem;
    gap: .5rem;
    background-color: var(--secondary-color);
    color: inherit;
    // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.06), 0 5px 4px -4px rgba(0, 0, 0, 0.02);
    font-weight: 500;

    &:hover {
        border: 1px solid var(--color0);
        color: var(--color0);
    }
}

.controls {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
    padding: 1rem .7rem;

    .confirm {
        border-radius: .2rem;
        background-color: var(--active);

        &:hover {
            color: var(--color0);
            background-color: var(--secondary-color);
        }
    }

    .dismiss {
        border-radius: .2rem;
        background-color: var(--clr-gray300);
        color: var(--clr-gray500);
        border: none;

        &:hover {
            color: var(--secondary-color);
            background-color: var(--clr-gray400);
        }
    }
}